<template>
  <section class="container">
    <nav class="navBreadcrumb">
      <ol class="breadcrumb">
        <li class="breadcrumb-item">
          <router-link :to="{ name: 'home' }">Home</router-link>
        </li>
        <li class="breadcrumb-item-active">
          <span> Dicas de Equipamentos</span>
        </li>
      </ol>
    </nav>
    <article class="container">Página em constução</article>
  </section>
</template>

<script>
export default {
  name: "Scubalab",
};
</script>

<style scoped>
.container {
  display: grid;
  grid-template-columns: 1fr;
  align-items: center;
  justify-items: center;
  /* padding: 0 20px; */
  max-width: 1200px;
  margin: 0 auto;
  grid-gap: 30px;
}
/* ############################ 
#       ÍCONES 
#############################*/

@media screen and (max-width: 768px) {
}
@media screen and (max-width: 550px) {
}
</style>