<template>
  <section class="container">
    <!-- <nav class="navBreadcrumb">
      <ol class="breadcrumb">
        <li class="breadcrumb-item">
          <router-link :to="{ name: 'viaturas' }"
            >Lista de Viaturas</router-link
          >
        </li>
        <li class="breadcrumb-item">
          <router-link
            :to="{
              name: 'viatura',
              params: { idVehicle: idVehicle },
            }"
            >Dados da Viatura
          </router-link>
        </li>
        <li class="breadcrumb-item-active">
          <span> Revisões da Viatura</span>
        </li>
      </ol>
    </nav> -->
    <article class="containerVideo">
      <div class="contentVideo">
        <h1>"Descubra as maravilhas do mundo submerso!"</h1>
      </div>
      <figure class="videoNone">
        <img
          class="videoImg"
          src="@/assets/video/poster.webp"
          alt="Foto Home Scubalab Mergulho"
        />
      </figure>
      <video
        id="video"
        playsinline="playsinline"
        autoplay="true"
        muted
        loop
        poster="@/assets/video/poster.webp"
      >
        <source src="@/assets/video/video.mp4" type="video/mp4" />
        <!-- <source src="@/assets/video/video.webm" type="video/webm" /> -->
      </video>
    </article>
    <article class="menuItens">
      <div>
        <router-link
          :to="{
            name: 'curso',
            params: { typeCourse: 'curso-basico-de-mergulho' },
          }"
        >
          <figure>
            <img
              src="@/assets/icon/svg/home/diveIcon.svg"
              class="img"
              alt="Curso Básico de Mergulho"
            />
          </figure>
          <h3>Aprenda a Mergulhar</h3>
          <p class="text24">Conheça o mundo submerso!</p>
        </router-link>
      </div>
      <div>
        <router-link
          :to="{
            name: 'cursos',
          }"
        >
          <figure>
            <img
              src="@/assets/icon/svg/home/certifyIcon.svg"
              class="img"
              alt="Cursos de Mergulho"
            />
          </figure>
          <h3>Já Mergulha?</h3>
          <p class="text24">Conheça outros cursos!</p>
        </router-link>
      </div>
      <div>
        <router-link
          :to="{
            name: 'viagens',
          }"
        >
          <figure>
            <img
              src="@/assets/icon/svg/home/boatIcon.svg"
              class="img"
              alt="Viagens de Mergulho"
            />
          </figure>
          <h3>Viaje e Mergulhe</h3>
          <p class="text24">Divirta-se e conheça novos destinos!</p>
        </router-link>
      </div>
      <div>
        <router-link
          :to="{
            name: 'manutencao',
          }"
        >
          <figure>
            <img
              src="@/assets/icon/svg/home/equipamentIcon.svg"
              class="img"
              alt="Manutenção de Equipamentos de Mergulho"
            />
          </figure>
          <h3>Manutenção de Equipamentos</h3>
          <p class="text24">Mergulhe com segurança!</p>
        </router-link>
      </div>
      <!-- <div>
        <a href="https://loja.scubalab.com.br/" target="blank">
          <figure>
            <img
              src="@/assets/icon/svg/home/sotreIcon.svg"
              class="img"
              alt="Loja Virtual - Scubalab"
            />
          </figure>
          <h3>Loja Virtual</h3>
          <p class="text24">Equipe-se e aproveite o seu mergulho!</p>
        </a>
      </div> -->
      <div>
        <!-- <router-link
          :to="{
            name: 'https://youtube.com/watch?v=wSabT1C0zsE&list=PLPYkJT_wPrpBKNvzsOZsJqrqfvn9jku5u',
          }"
        > -->
        <a href="https://www.youtube.com/watch?v=wSabT1C0zsE&list=PLPYkJT_wPrpBKNvzsOZsJqrqfvn9jku5u" target="blank"
                >
          <figure>
            <img
              src="@/assets/icon/svg/home/divingTipsIcon.svg"
              class="img"
              alt="Dicas de Equipamentos de Mergulho"
            />
          </figure>
          <h3>Dicas de Equipamentos</h3>
          <p class="text24">Conheça melhor os equipamentos!</p>
          </a>
        <!-- </router-link> -->
      </div>
    </article>
    <article class="containerCalendar">
      <div class="containerImg">
        <div class="calendarItem">
          <figure>
            <img
              src="@/assets/icon/svg/calendars.svg"
              class="img"
              alt="Calendário Scubalab"
            />
          </figure>
          <h3>Calendário</h3>
          <p class="text24">Cursos, Viagens e Eventos</p>
          <router-link :to="{ name: 'calendario' }">
            <span class="btn">Veja Aqui!</span>
          </router-link>
        </div>
      </div>
    </article>
    <!-- <article class="containerDepoiments">
      <h2>Depoimentos de mergulhadores formados por nós</h2>
      <div class="depoimentItens">
        <figure>
          <img
            src="@/assets/depoiment1.svg"
            class="img"
            alt="Youtube Scubalab"
          />
        </figure>
        <div>
          <h3>Cacilda K.</h3>
          <p class="text24">
            "Excelência em ensino de mergulho!! O método de ensino e seus
            instrutores são ótimos!! Me sinto muito confiante para mergulhar!!"
          </p>
        </div>
      </div>
      <div class="depoimentItens">
        <figure>
          <img
            src="@/assets/depoiment2.svg"
            class="img"
            alt="Youtube Scubalab"
          />
        </figure>
        <div>
          <h3>Marcopollo S.</h3>
          <p class="text24">
            " Há mais ou menos 2 anos atrás, fiz um mergulho em arraial do cabo
            e me apaixonei , vi que queria me especializar no assunto e foi
            então que comecei a fazer uma pesquisa das escolas na região de SP,
            separei algumas e comecei a ligar. E quando eu falava que estava
            fazendo uma pesquisa e falava o nome das escolas, quando chegava o
            nome scubalab, até os concorrentes elogiavam, chegou um monte que
            parei tudo e fui lá pessoalmente conhecer, foi aí que me apaixonei
            rsrs, super profissionais, organizados, experientes e fora que são
            amáveis!!!!! Me tornei grande amigo e fã da família scubalab!!!!"
          </p>
        </div>
      </div>
      <div class="depoimentItens">
        <figure>
          <img
            src="@/assets/depoiment3.svg"
            class="img"
            alt="Youtube Scubalab"
          />
        </figure>
        <div>
          <h3>Thaís P.</h3>
          <p class="text24">
            " Mergulhar com a Scubalab foi uma das melhores coisas que já fiz na
            minha vida. Sempre tive muito medo de água e do mar mas ao mesmo
            tempo sempre tive muita vontade. Com toda a paciência do mundo dos
            professores da escola, consegui enfrentar e superar o medo para
            seguir em frente. Hoje com 8 certificações posso garantir o quanto -
            além de extremamente atenciosos e cuidadosos - são igualmente
            competentes e profissionais. Recomendo a todos que se permitam viver
            essa experiência que é o mergulho, com a segurança e atenção
            individual que a Scubalab proporciona."
          </p>
        </div>
      </div>
    </article> -->
    <article class="containerDepoiments">
      <h2>Depoimentos de mergulhadores formados por nós</h2>

      <ul>
        <li v-for="depoiment in depoiments" :key="depoiment.id">
          <div class="depoimentItens">
            <figure>
              <img
                :src="getImgUrl(depoiment.img)"
                alt="Foto de mergulhador formado pela scubalab"
              />
            </figure>
            <div>
              <h3>{{ depoiment.name }}</h3>
              <p class="text24">
                {{ depoiment.description }}
              </p>
            </div>
          </div>
        </li>
      </ul>
    </article>
    <article class="containerTravels">
      <div class="travels">
        <figure>
          <img
            src="@/assets/icon/svg/home/travelsImage.svg"
            class="img"
            alt="Destinos Scubalab"
          />
        </figure>
        <div>
          <h2>Para quem é o mergulho?</h2>
          <ul class="circle">
            <li>
              Para quem quer sair da rotina e maravilhar-se com essa beleza
              única que o mundo nos proporciona.
            </li>
            <li>
              Para quem quer uma atividade descontraída, relaxante e saudável.
            </li>
            <li>
              Para quem quer aliviar a tensão ficando longe de trânsito e toda a
              poluição sonora da cidade, ter a sensação de paz.
            </li>
            <li>
              Para quem quer conhecer pessoas e trocar experiências de mergulho
              e de vida. Inclusive, os nosso profissionais possuem muitas
              histórias interessantes para compartilhar.
            </li>
          </ul>
        </div>
      </div>
    </article>
  </section>
</template>

<script>
import depoimentsData from "@/api/depoiments.json";
// import { mapState } from "vuex";
export default {
  name: "Scubalab",
  data() {
    return {
      depoiments: depoimentsData,
    };
  },
  metaInfo() {
    return {
      meta: [
        {
          name: "description",
          content:
            "Scubalab Mergulho - Centro de Mergulho - Descubra a Maravilhas do Mundo Submerso",
        },
        { name: "robots", content: "index, follow" },
        { property: "og:locale", content: "pt-br" },
        {
          property: "og:type",
          content: "website",
        },
        {
          property: "og:title",
          content: "Scubalab Mergulho",
        },
        {
          property: "og:description",
          content:
            "Scubalab Mergulho - Centro de Mergulho - Descubra a Maravilhas do Mundo Submerso",
        },
        {
          property: "og:url",
          content: "https://www.scubalab.com.br/",
        },
        {
          property: "og:site_name",
          content: "Scubalab Mergulho",
        },
        {
          property: "og:image",
          content: process.env.VUE_APP_BASE_URL + "/open-graph-scubalab.png",
        },
        {
          property: "og:image:alt",
          content: "Scubalab Mergulho",
        },
        {
          property: "article:tag",
          content:
            "Scubalab Mergulho - Centro de Mergulho - Descubra a Maravilhas do Mundo Submerso",
        },
        {
          property: "twitter:image",
          content: process.env.VUE_APP_BASE_URL + "/open-graph-scubalab.png",
        },
        {
          property: "twitter:title",
          content: "Scubalab Mergulho",
        },
        {
          name: "twitter:card",
          content: "summary",
        },
        {
          property: "twitter:description",
          content:
            "Scubalab Mergulho - Centro de Mergulho - Descubra a Maravilhas do Mundo Submerso",
        },
      ],
    };
  },
  methods: {
    getImgUrl(img) {
      return require("@/assets/home/" + img);
    },
  },
  computed: {
    // ...mapState(["depoiments"]),
  },
};
</script>

<style scoped>
ul.circle {
  /* list-style-type: disc; */
  color: var(--white);
  padding-bottom: 30px;
  text-align: justify;
}
/*  */
.circle li::before {
  content: "●";
  color: var(--lightBlue);
  /* line-height: var(--lnH3); */
  display: inline-block;
  width: 1em;
  margin-right: 0.5em;
  text-align: right;
  direction: rtl;
  padding-bottom: 10px;
}
.containerTravels {
  background: var(--blue);
  margin: 60px auto 0 auto;
  padding: 60px 0;
}
.travels {
  display: grid;
  grid-template-columns: minmax(auto, 430px) minmax(200px, 500px);
  align-items: center;
  justify-items: center;
  padding: 0 20px;
  max-width: 1200px;
  margin: 0 auto;
  grid-gap: 30px;
}
.travels h2 {
  color: var(--lightBlue);
  padding-bottom: 30px;
}
.containerDepoiments {
  display: grid;
  align-items: center;
  justify-items: center;
  padding: 20px 0 60px 0;
  max-width: 800px;
  margin: 0 auto;
}
.containerDepoiments ul {
  display: grid;
  grid-gap: 30px;
  margin-top: 30px;
}
.depoimentItens {
  display: grid;
  grid-template-columns: 211px 1fr;
  justify-items: flex-start;
  align-items: center;
  width: 100%;
  /* max-width: 1200px; */
  margin: 0 auto;
  grid-gap: 40px;
}
.containerCalendar {
  background-image: linear-gradient(
    0deg,
    rgb(18, 100, 167) 0%,
    rgb(1, 149, 223) 100%
  );
  height: 350px;
  margin: 60px auto;
}

.containerImg {
  background-image: url("~@/assets/fundoHome.webp");
  height: 100%;
  background-repeat: no-repeat;
  background-position: top;
  display: grid;
  grid-template-columns: 1fr 2fr;
  align-items: center;
  justify-items: center;
  /* padding: 60px 20px; */
  /* max-width: 1200px; */
  margin: 0 auto;
  grid-gap: 10px;
}
.calendarItem {
  grid-column: 2;
  /* padding-right: 400px; */
  align-items: center;
  justify-items: center;
  text-align: center;
}

.calendarItem h3,
.calendarItem p {
  color: var(--white);
}

.menuItens {
  display: flex;
  /*grid-template-columns: repeat(3, 300px);*/
  align-items: flex-end;
  justify-content: space-around;
  padding: 60px 20px;
  max-width: 800px;
  margin: 0 auto;
  grid-gap: 20px;
  flex-wrap: wrap;
}
.menuItens div {
  justify-content: center;
  text-align: center;
  max-width: 300px;
}
/* ############################ 
#       CONTAINER VIDEO 
#############################*/
.containerVideo {
  /* background: var(--blue); */
  /* margin: 60px auto; */
  width: 100%;
  height: 100%;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  /* position: absolute; */
}
.contentVideo {
  display: flex;
  /* padding: max(30px, 10vw); */
  width: 100%;
  height: max(56vw);
  background: #031c36a2;
  position: absolute;
  align-items: center;
  justify-items: center;
  justify-content: center;
  align-items: center;
  /* display: grid;
  grid-template-columns: minmax(auto, 360px) minmax(300px, 840px);
  padding: 60px 20px;
  max-width: 1200px;
  margin: 0 auto;
  grid-gap: 10px; */
}

.contentVideo h1 {
  max-width: max(100px, 600px);
  text-align: center;
  color: #fff;
  font-weight: normal;
}

.videoNone {
  display: none;
}
#video {
  /* max-width: 600px; */
  width: 100%;
  /* border-radius: 5px; */
}

/* ############################ 
#       ÍCONES 
#############################*/

@media screen and (max-width: 768px) {
  .travels figure {
    flex: 1 1 300px;
    display: flex;
  }
  .menuItens {
    grid-template-columns: repeat(2, 300px);
  }
  .travels {
    height: 100%;
    background-repeat: no-repeat;
    background-position: initial;
    grid-template-columns: 1fr;
  }
  .travels img {
    width: 100%;
  }
  /* .contentVideo {
    grid-template-columns: 1fr;
  } */
  .containerImg {
    background-image: url("~@/assets/fundoHome.webp");
    height: 100%;
    background-repeat: no-repeat;
    background-position: initial;
    grid-template-columns: 1fr;
  }
  .calendarItem {
    grid-column: 2;
    padding-right: 100px;
  }
}
@media screen and (max-width: 550px) {
  .videoNone {
    display: block;
  }
  .videoNone img {
    width: 100%;
    height: max(56vw);
  }
  video {
    display: none;
  }
  .containerDepoiments {
    text-align: center;
    margin-bottom: 60px;
  }
  .containerDepoiments,
  .depoimentItens {
    grid-template-columns: 1fr;
    padding: 0 20px;
    justify-items: center;
  }
  .containerImg,
  .menuItens {
    grid-template-columns: 1fr;
  }
  .calendarItem {
    grid-template-columns: 1fr;
    grid-column: 1;
    padding: 300px 0 0 0;
  }
  .containerCalendar {
    height: 500px;
    justify-content: center;
    justify-items: center;
  }
}
</style>