import Vue from "vue";
import Vuex from "vuex";
import course from "@/api/course.json";
// import { api } from "@/services.js";
// import depoimentsData from "@/api/depoiments.json";
// import router from './../router/index.js'


Vue.use(Vuex);

export default new Vuex.Store({
  modules: {},
  strict: process.env.NODE_ENV !== 'production', //fica verificando se está fazendo alterações direto no state sem mutation - habilita somente em modo de desenvolvimento.
  state: {
    courses: null,
    course:null,
    depoiments: null
  },
  getters: {

  },
  mutations: {
    update_courses(state, payload) {
      // console.log(payload)
      state.courses = payload
    },
    update_depoiments(state, payload) {
      state.depoiments = payload
    }
  },
  actions: {
    getCourses(context) {
      console.log("chamou", context)
      console.log("chamou", course)
      fetch(`./api/couse.json`)
      
      
      .then(r => r.json())
      .then(r => {
       console.log("response", r)        // this.courses = r;
        context.commit("update_courses", r)
      })
    }
    // getDepoiments(context) {
    //   // context.commit("RESET_FILTER", null);
    //   // context.commit("UPDATE_LOADING", true);
    //   api.get(`/`)
    //     .then(
    //       response => {
    //         context.commit("update_depoiments", response.data);
    //         context.commit("UPDATE_LOADING", false);
    //       })
    //     .catch(error => {
    //       context.commit("UPDATE_LOADING", false);
    //       console.log(error)
    //       const status = error.response.status
    //       context.dispatch("errorStatus", status, { root: true })
    //     })
    // },
    // getDepoiment(context) {
    //   fetch(`@/api/depoiments.json`)
    //     .then(response => response.json())
    //     .then(response => {
    //       context.commit("update_depoiments", response);
    //       // this.local = response  //aqui está dizendo que vai pegar todo aquele array e armazenar em "local", pode ser o nome que quiser.
    //     })
    // }
  }
});