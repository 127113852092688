<template>
  <header id="topo">
    <section class="header" :class="headerVideo">
      <nav class="header-menu" :class="fontVideo">
        <figure class="logo">
          <router-link
            @click.native="uncheck"
            :to="{
              name: 'home',
            }"
          >
            <img
              class="logoDesktop"
              src="@/assets/logo-scubalab-mergulho.webp"
              alt="Logo Scubalab Mergulho"
            />
          </router-link>
        </figure>
        <input type="checkbox" id="check" class="mt-0" />
        <label id="icone" for="check" class="mt-0">
          <span class="navicon"></span>
        </label>
        <div class="barra mt-0">
          <ul class="menu text24">
            <li class="link">
              <router-link
                @click.native="uncheck"
                :to="{
                  name: 'home',
                }"
                >Home</router-link
              >
            </li>
            <li class="link">
              <router-link @click.native="uncheck" :to="{ name: 'sobre' }"
                >Sobre</router-link
              >
            </li>
            <li class="link">
              <router-link @click.native="uncheck" :to="{ name: 'cursos' }"
                >Cursos</router-link
              >
            </li>
            <!-- <li class="link">
              <router-link @click.native="uncheck" :to="{ name: 'viagens' }">
                Viagens</router-link
              >
            </li>
            <li class="link">
              <router-link @click.native="uncheck" :to="{ name: 'servicos' }">
                Serviços</router-link
              >
            </li> -->
            <li class="link">
              <router-link @click.native="uncheck" :to="{ name: 'calendario' }"
                >Calendário</router-link
              >
            </li>
            <li class="link">
              <a href="https://cressi.com.br/" target="blank"
                >Catálogo Cressi</a
              >
            </li>
            <!-- <li class="link">
              <router-link @click.native="uncheck" :to="{ name: 'contato' }">
                Contato</router-link
              >
            </li> -->
          </ul>
        </div>
      </nav>
    </section>
  </header>
</template>

<script>
export default {
  name: "Header",
  computed: {
    headerVideo() {
      return this.$route.name == "home" ? "true" : "nothing";
    },
    fontVideo() {
      return this.$route.name == "home" ? "colorMenu" : "nothing";
    },
  },
  methods: {
    uncheck: function (event) {
      // console.log(event);
      if (event) {
        document.getElementById("check").checked = false;
      }
    },
  },
  created() {
    console.log(this.$route.name == "home");
  },
};
</script>

<style scoped>
.true {
  position: absolute;
  display: grid;
  justify-content: center;
  width: 100%;
  color: #fff;
  left: 0;
  right: 0;
}
.header-menu.colorMenu ul li a {
  color: var(--lightBlue);
}
.header-menu.colorMenu ul li a:hover {
  color: var(--white);
}
section.header {
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  align-items: center;
  align-content: center;
  width: 100%;
  /* background: var(--white); */
  padding-top: 20px;
  padding-bottom: 20px;
  z-index: 20;
  /* font-family: "Times New Roman", Times, serif; */
}
.header-menu {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: center;
  align-content: center;
  /* max-width: 1200px; */
  /* margin: 0 auto; */
  padding: 0px 20px;
  flex: 1 0 158px;
}
.logo {
  z-index: 99;
}
.logo a {
  display: flex;
  align-items: flex-end;
}

.header-menu ul {
  display: flex;
}

.header-menu ul li a {
  margin: 0 10px;
  color: var(--blue);
  padding: 10px 0;
}
.header-menu ul li a:hover {
  color: var(--darkBlue);
  text-decoration: underline;
  text-underline-offset: 4px;
  text-underline-position: under;
}
.header-menu .menu {
  clear: both;
  max-height: 0;
  transition: max-height 0.2s ease-out;
}

/* menu icon */

.logoMobile,
#check {
  display: none;
}

/*fim menu*/

@media (max-width: 768px) {
  .true {
    position: unset;
  }
  .logoMobile {
    padding-top: 20px;
    display: block;
  }
  /* menu btn */
  #icone {
    cursor: pointer;
    display: flex;
    justify-content: flex-end;
    position: relative;
    user-select: none;
    z-index: 5;
  }
  #icone {
    background: var(--blue);
    width: 46px;
    height: 46px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
  }

  #icone .navicon {
    background: var(--lightBlue);
    display: block;
    height: 2px;
    position: relative;
    transition: background 0.2s ease-out;
    width: 21.77px;
    height: 2px;
    border-radius: 2px;
  }

  #icone .navicon:before,
  #icone .navicon:after {
    background: var(--lightBlue);
    content: "";
    display: block;
    height: 100%;
    position: absolute;
    transition: all 0.2s ease-out;
    width: 100%;
    height: 2px;
    border-radius: 2px;
  }

  #icone .navicon:before {
    top: 5px;
  }

  #icone .navicon:after {
    top: -5px;
  }

  #check:checked ~ #icone .navicon {
    background: transparent;
  }

  #check:checked ~ #icone .navicon:before {
    transform: rotate(-45deg);
  }

  #check:checked ~ #icone .navicon:after {
    transform: rotate(45deg);
  }

  #check:checked ~ #icone:not(.steps) .navicon:before,
  #check:checked ~ #icone:not(.steps) .navicon:after {
    top: 0;
  }
  /*fim btn*/

  /* background */
  .barra {
    background-color: var(--blue);
    height: 100%;
    width: 100%;
    position: absolute;
    transition: all 0.2s linear;
    top: -100%;
    left: 0;
    z-index: 4;
  }
  .header-menu {
    padding: 0 20px;
  }
  .header-menu ul {
    width: 100%;
    position: absolute;
    top: 100px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .header-menu ul li a {
    color: var(--lightBlue);
    text-decoration: none;
    font-size: 1.25em;
    color: var(--white);
  }
  .header-menu ul li {
    padding: 15px;
    transition: all 0.2s linear;
    opacity: 0;
    margin-left: 200px;
    z-index: 1;
  }

  .header-menu ul li a:hover {
    transition: all 0.1s linear;
    color: #d0dcd9;
  }

  #check:checked ~ .barra {
    transform: translatey(100%);
  }

  #check:checked ~ .barra ul .link {
    opacity: 1;
    margin-left: 0;
    transition-delay: 0.2s;
  }

  #check:checked ~ #icone {
    border: 2px solid var(--lightBlue);
    z-index: 5;
  }
}
</style>