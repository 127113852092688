<template>
  <footer>
    <a
      href="https://api.whatsapp.com/send?phone=5511910877390&text=Quero%20mergulhar!"
      target="_blank"
      class="wppFixed"
    >
      <!-- <svg
        enable-background="new 0 0 512 512"
        class="wppIcon"
        version="1.1"
        viewBox="0 0 512 512"
        xml:space="preserve"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M256.064,0h-0.128l0,0C114.784,0,0,114.816,0,256c0,56,18.048,107.904,48.736,150.048l-31.904,95.104  l98.4-31.456C155.712,496.512,204,512,256.064,512C397.216,512,512,397.152,512,256S397.216,0,256.064,0z"
          fill="#4CAF50"
        />
        <path
          d="m405.02 361.5c-6.176 17.44-30.688 31.904-50.24 36.128-13.376 2.848-30.848 5.12-89.664-19.264-75.232-31.168-123.68-107.62-127.46-112.58-3.616-4.96-30.4-40.48-30.4-77.216s18.656-54.624 26.176-62.304c6.176-6.304 16.384-9.184 26.176-9.184 3.168 0 6.016 0.16 8.576 0.288 7.52 0.32 11.296 0.768 16.256 12.64 6.176 14.88 21.216 51.616 23.008 55.392 1.824 3.776 3.648 8.896 1.088 13.856-2.4 5.12-4.512 7.392-8.288 11.744s-7.36 7.68-11.136 12.352c-3.456 4.064-7.36 8.416-3.008 15.936 4.352 7.36 19.392 31.904 41.536 51.616 28.576 25.44 51.744 33.568 60.032 37.024 6.176 2.56 13.536 1.952 18.048-2.848 5.728-6.176 12.8-16.416 20-26.496 5.12-7.232 11.584-8.128 18.368-5.568 6.912 2.4 43.488 20.48 51.008 24.224 7.52 3.776 12.48 5.568 14.304 8.736 1.792 3.168 1.792 18.048-4.384 35.52z"
          fill="#FAFAFA"
        />
      </svg> -->
      <svg viewBox="0 0 32 32" class="wppIcon">
        <path
          d=" M19.11 17.205c-.372 0-1.088 1.39-1.518 1.39a.63.63 0 0 1-.315-.1c-.802-.402-1.504-.817-2.163-1.447-.545-.516-1.146-1.29-1.46-1.963a.426.426 0 0 1-.073-.215c0-.33.99-.945.99-1.49 0-.143-.73-2.09-.832-2.335-.143-.372-.214-.487-.6-.487-.187 0-.36-.043-.53-.043-.302 0-.53.115-.746.315-.688.645-1.032 1.318-1.06 2.264v.114c-.015.99.472 1.977 1.017 2.78 1.23 1.82 2.506 3.41 4.554 4.34.616.287 2.035.888 2.722.888.817 0 2.15-.515 2.478-1.318.13-.33.244-.73.244-1.088 0-.058 0-.144-.03-.215-.1-.172-2.434-1.39-2.678-1.39zm-2.908 7.593c-1.747 0-3.48-.53-4.942-1.49L7.793 24.41l1.132-3.337a8.955 8.955 0 0 1-1.72-5.272c0-4.955 4.04-8.995 8.997-8.995S25.2 10.845 25.2 15.8c0 4.958-4.04 8.998-8.998 8.998zm0-19.798c-5.96 0-10.8 4.842-10.8 10.8 0 1.964.53 3.898 1.546 5.574L5 27.176l5.974-1.92a10.807 10.807 0 0 0 16.03-9.455c0-5.958-4.842-10.8-10.802-10.8z"
          fill-rule="evenodd"
        ></path>
      </svg>
    </a>
    <button class="top" @click="scrollToTop">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        class="topIcon"
        viewBox="0 0 24 24"
        fill="none"
        stroke-width="2"
        stroke-linecap="square"
        stroke-linejoin="arcs"
      >
        <path d="M18 15l-6-6-6 6" />
      </svg>
    </button>
    <section
      class="containerInfo"
      itemscope
      itemtype="http://schema.org/PostalAddress"
    >
      <article class="infosItem">
        <div class="infoItens">
          <figure>
            <img
              src="@/assets/icon/svg/locationIcon.svg"
              class="img"
              alt="Endereço da Scubalab"
            />
          </figure>
          <p itemprop="streetAddress">
            Rua: Quinze de Agosto- n°77 - Centro - Cep 09721-110
          </p>
        </div>
        <div class="infoItens">
          <figure>
            <img
              src="@/assets/icon/svg/phoneIcon.svg"
              class="img"
              alt="Telefone Scubalab"
            />
          </figure>
          <p itemprop="telephone">Tel.: (11)4339-7390 | (11) 4930-1002</p>
        </div>
        <div class="infoItens">
          <figure>
            <img
              src="@/assets/icon/svg/wppIcon.svg"
              class="img"
              alt="Whatsapp Scubalab"
            />
          </figure>
          <p itemprop="telephone">Whatsapp: (11) 91087-7390</p>
        </div>
        <div class="infoItens">
          <figure>
            <img
              src="@/assets/icon/svg/emailIcon.svg"
              class="img"
              alt="Email Scubalab"
            />
          </figure>
          <p itemprop="email">E-mail: contato@scubalab.com.br</p>
        </div>
        <div
          class="infoItens"
          itemscope
          itemtype="https://schema.org/OpeningHoursSpecification"
        >
          <figure>
            <img
              src="@/assets/icon/svg/openingHoursIcon.svg"
              class="img"
              alt="Horário da Scubalab"
            />
          </figure>
          <p itemprop="opens" datetime="00:00:00">
            Segunda a Sexta das 8h às 18h e Sábado das 8h às 12h
          </p>
        </div>
      </article>
      <article class="contactItem">
        <div>
            <p>Certificadora Internacional:</p>        
            <div>
                <figure>
                <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"	 viewBox="0 0 912.82 363.31" style="enable-background:new 0 0 912.82 363.31;" xml:space="preserve"><g id="Layer_1">
    </g>
                    <g id="Layer_2">
                        <g>
                            <g>
                                <g>
                                    <path class="st0" d="M908.73,354.78c1.14,0,2.16,0.46,2.9,1.2s1.19,1.77,1.19,2.91c0,1.15-0.45,2.18-1.19,2.92
                                        c-0.74,0.74-1.77,1.19-2.91,1.19c-1.14,0-2.17-0.45-2.92-1.19c-0.75-0.74-1.21-1.77-1.21-2.92c0-1.14,0.46-2.17,1.21-2.91
                                        s1.78-1.2,2.92-1.2H908.73L908.73,354.78z M908.71,355.42c-0.91,0-1.75,0.39-2.35,1.02c-0.6,0.63-0.98,1.5-0.98,2.46
                                        c0,0.97,0.37,1.84,0.98,2.47c0.6,0.63,1.44,1.01,2.36,1.01c0.93,0.01,1.76-0.37,2.37-1s0.98-1.5,0.98-2.47
                                        c0-0.97-0.37-1.84-0.98-2.47s-1.44-1.02-2.37-1.02H908.71L908.71,355.42z M907.93,361.28h-0.37h-0.37v-2.29v-2.29
                                        c0.2-0.03,0.39-0.06,0.6-0.08s0.44-0.03,0.72-0.03c0.35,0,0.64,0.04,0.88,0.1c0.24,0.06,0.42,0.15,0.55,0.25
                                        c0.14,0.1,0.25,0.23,0.32,0.39c0.07,0.16,0.11,0.34,0.11,0.56c0,0.3-0.1,0.54-0.27,0.73c-0.17,0.19-0.39,0.31-0.64,0.39v0.02
                                        v0.02c0.21,0.04,0.38,0.14,0.51,0.33c0.13,0.18,0.22,0.44,0.27,0.78c0.06,0.35,0.11,0.6,0.16,0.78
                                        c0.05,0.18,0.09,0.28,0.13,0.35h-0.39h-0.39c-0.05-0.07-0.1-0.22-0.16-0.42c-0.05-0.2-0.11-0.46-0.17-0.77
                                        c-0.04-0.22-0.16-0.42-0.32-0.56c-0.16-0.14-0.37-0.22-0.6-0.22h-0.29h-0.29v0.98V361.28z M907.93,358.74h0.26h0.26
                                        c0.31,0,0.59-0.05,0.8-0.18c0.21-0.12,0.33-0.32,0.33-0.6c0-0.2-0.07-0.41-0.25-0.56c-0.18-0.15-0.46-0.25-0.88-0.25
                                        c-0.11,0-0.22,0-0.3,0.01c-0.09,0.01-0.16,0.02-0.23,0.02v0.77V358.74z"/>
                                </g>
                            </g>
                            <g>
                                <path class="st1" d="M869.66,245.95h15.33h15.33c0.27,0,0.52,0.11,0.7,0.29c0.18,0.18,0.29,0.43,0.29,0.7v12.07v12.07
                                    c0,0.41-0.24,0.73-0.56,0.89c-0.32,0.15-0.72,0.14-1.04-0.11l-15.33-12.07l-15.33-12.07c-0.37-0.29-0.45-0.73-0.32-1.1
                                    C868.85,246.25,869.19,245.95,869.66,245.95z"/>
                            </g>
                            <path class="st2" d="M867.68,299.62c-4.18,1.62-8.13,2.72-11.91,3.41c-3.79,0.69-7.41,0.97-10.94,0.97
                                c-8.27,0-16.01-1.56-24.07-3.13c-8.06-1.56-16.43-3.13-25.96-3.13c-2.76,0-5.62,0.13-8.6,0.43s-6.07,0.77-9.31,1.45
                                c-15.81,3.31-27.31,11.63-35.41,20.37c-8.1,8.75-12.8,17.92-14.99,22.95c-0.14,0.31-0.08,0.61,0.08,0.84s0.43,0.37,0.71,0.37
                                c0.1,0,0.2-0.02,0.29-0.06c0.1-0.04,0.19-0.1,0.28-0.18c3.34-3.08,9.51-8.07,18.27-12.29c8.76-4.22,20.12-7.68,33.81-7.68
                                c3.27,0,6.68,0.2,10.22,0.63c3.54,0.43,7.21,1.1,11,2.04c3.16,0.78,6.19,1.34,9.11,1.7s5.72,0.53,8.41,0.53
                                c14.97,0,26.39-5.26,34.6-11.38c8.22-6.13,13.23-13.12,15.42-16.59c0.19-0.3,0.17-0.63,0.02-0.88c-0.15-0.25-0.42-0.43-0.73-0.43
                                c-0.05,0-0.1,0-0.16,0.01C867.79,299.58,867.74,299.59,867.68,299.62z"/>
                            <g>
                                <path class="st0" d="M775.82,294.49c2.82-0.59,5.6-1.04,8.37-1.37s5.54-0.52,8.33-0.59c0.27-0.01,0.51-0.12,0.68-0.29
                                    c0.17-0.18,0.28-0.42,0.28-0.69v-8.03v-8.03c0-0.27,0.11-0.52,0.29-0.7s0.43-0.29,0.7-0.29h30.77h30.77
                                    c0.27,0,0.52-0.11,0.7-0.29c0.18-0.18,0.29-0.43,0.29-0.7v-13.28v-13.28c0-0.27-0.11-0.52-0.29-0.7s-0.43-0.29-0.7-0.29h-49.06
                                    H757.9c-0.27,0-0.52,0.11-0.7,0.29c-0.18,0.18-0.29,0.43-0.29,0.7v19.62v19.62v6.78v6.78c0,0.37,0.19,0.67,0.47,0.83
                                    c0.28,0.17,0.63,0.2,0.96,0.03c2.59-1.31,5.34-2.51,8.25-3.54C769.5,296.04,772.57,295.17,775.82,294.49z"/>
                                <path class="st0" d="M779.9,329.18c-4.22,0-8.21,0.35-11.94,0.97s-7.23,1.49-10.47,2.53c-0.2,0.07-0.38,0.2-0.49,0.36
                                    c-0.12,0.17-0.19,0.37-0.19,0.59v14.25v14.25c0,0.27,0.11,0.52,0.29,0.7c0.18,0.18,0.43,0.29,0.7,0.29h14.51h14.51h2.84h2.84
                                    c0.27,0,0.52-0.11,0.7-0.29c0.18-0.18,0.29-0.43,0.29-0.7v-15.45v-15.45c0-0.24-0.09-0.47-0.24-0.65
                                    c-0.15-0.18-0.36-0.3-0.6-0.34c-2.15-0.35-4.3-0.61-6.42-0.78C784.1,329.27,781.99,329.18,779.9,329.18z"/>
                            </g>
                            <g>
                                <path class="st0" d="M864.85,254.02v20.22v20.22c0,0.16,0.08,0.29,0.2,0.37c0.12,0.08,0.27,0.1,0.41,0.03
                                    c0.4-0.18,0.82-0.32,1.25-0.41c0.42-0.09,0.86-0.14,1.29-0.14c1.5,0,2.92,0.55,4.01,1.52s1.86,2.33,2.06,3.97
                                    c0.08,0.69,0.02,1.39-0.15,2.06c-0.17,0.67-0.46,1.31-0.83,1.9c-0.72,1.14-1.75,2.66-3.1,4.42s-3.02,3.77-5.02,5.86
                                    c-0.04,0.04-0.07,0.09-0.09,0.14c-0.02,0.05-0.03,0.11-0.03,0.16v23.84v23.84c0,0.27,0.11,0.52,0.29,0.7
                                    c0.18,0.18,0.43,0.29,0.7,0.29h17.24h17.24c0.27,0,0.52-0.11,0.7-0.29c0.18-0.18,0.29-0.43,0.29-0.7v-40.44v-40.44
                                    c0-0.15-0.03-0.3-0.1-0.43c-0.06-0.13-0.16-0.25-0.28-0.34l-17.24-13.57l-17.24-13.57c-0.32-0.25-0.72-0.27-1.04-0.11
                                    C865.09,253.28,864.85,253.6,864.85,254.02z"/>
                            </g>
                            <path class="st0" d="M730.31,325.51c2.11-3.02,4.58-6.16,7.44-9.24s6.11-6.1,9.8-8.89c1.39-3.77,2.12-7.56,2.52-10.87
                                s0.45-6.14,0.48-8c0.05-2.82,0.16-8.71-1.27-15.21c-1.44-6.5-4.42-13.6-10.57-18.83c-2.46-2.1-5.13-3.67-7.37-4.77
                                c-2.25-1.1-4.07-1.72-4.87-1.93c-2.68-0.71-5.48-1.16-8.3-1.43c-2.82-0.28-5.66-0.37-8.43-0.37h-35.87h-35.87
                                c-0.27,0-0.52,0.11-0.7,0.29c-0.18,0.18-0.29,0.43-0.29,0.7v57.54v57.54c0,0.27,0.11,0.52,0.29,0.7c0.18,0.18,0.43,0.29,0.7,0.29
                                h17.31h17.31c0.27,0,0.52-0.11,0.7-0.29c0.18-0.18,0.29-0.43,0.29-0.7v-16.16v-16.16c0-0.27,0.11-0.52,0.29-0.7
                                c0.18-0.18,0.43-0.29,0.7-0.29h17.57h17.57c2.77,0,5.61-0.1,8.43-0.37c2.82-0.28,5.62-0.73,8.3-1.43c0.37-0.1,0.9-0.26,1.55-0.49
                                C728.67,326.19,729.45,325.89,730.31,325.51z M713.52,300.13L713.52,300.13L713.52,300.13l-19.47,0h-19.47
                                c-0.27,0-0.52-0.11-0.7-0.29s-0.29-0.43-0.29-0.7v-11.8v-11.8c0-0.27,0.11-0.52,0.29-0.7c0.18-0.18,0.43-0.29,0.7-0.29h19.46
                                h19.46c0,0,0,0,0.01,0c0,0,0,0,0.01,0c3.1,0,5.92,1.43,7.95,3.75c2.03,2.31,3.29,5.51,3.29,9.04c0,3.53-1.26,6.73-3.29,9.04
                                C719.44,298.7,716.63,300.13,713.52,300.13z"/>
                            <path class="st3" d="M442.68,281.28c0-4.41-1.6-7.89-4.65-10.27c-3.05-2.38-7.55-3.66-13.36-3.66c-2.8,0-5.05,0.06-6.83,0.16
                                c-1.78,0.1-3.11,0.24-4.09,0.39c-0.26,0.04-0.48,0.17-0.64,0.36c-0.16,0.19-0.25,0.43-0.25,0.69c0,2.06,0,7.42,0,12.83
                                c0,5.4,0,10.85,0,13.08c0,0.29,0.12,0.55,0.31,0.74s0.46,0.31,0.75,0.31h4.02h4.02c6.58,0,11.76-1.45,15.29-4.01
                                C440.79,289.34,442.68,285.68,442.68,281.28z"/>
                            <path class="st3" d="M418.48,318.83h-2.28h-2.28c-0.29,0-0.56,0.12-0.75,0.31c-0.19,0.19-0.31,0.46-0.31,0.75v21.19v21.19
                                c0,0.29,0.12,0.56,0.31,0.75c0.19,0.19,0.45,0.31,0.75,0.31c2.43,0,8.69,0,15.1,0c6.41,0,12.97,0,15.97,0
                                c0.39,0,0.71-0.2,0.89-0.49s0.22-0.66,0.06-1.02c-1.01-2.16-2.28-5.4-3.7-9.71c-1.42-4.32-2.99-9.71-4.59-16.18
                                c-1.75-7.05-3.83-11.32-6.83-13.83C427.82,319.57,423.9,318.83,418.48,318.83z"/>
                            <path class="st3" d="M345.22,274.65h15.69h15.69c0.29,0,0.56-0.12,0.75-0.31c0.19-0.19,0.31-0.46,0.31-0.75l0-13.24l0-13.24
                                c0-0.29-0.12-0.56-0.31-0.75c-0.19-0.19-0.45-0.31-0.74-0.31c-4.24,0-20.71,0-37.19,0c-16.47,0-32.95,0-37.21,0
                                c-0.29,0-0.56,0.12-0.75,0.31c-0.19,0.19-0.31,0.46-0.31,0.75v57.56v57.56c0,0.29,0.12,0.56,0.31,0.75
                                c0.19,0.19,0.46,0.31,0.75,0.31h37.19h37.19c0.29,0,0.56-0.12,0.75-0.31c0.19-0.19,0.31-0.46,0.31-0.75v-13.26v-13.26
                                c0-0.29-0.12-0.56-0.31-0.75c-0.19-0.19-0.46-0.31-0.75-0.31h-15.69h-15.69c-0.29,0-0.56-0.12-0.75-0.31s-0.31-0.46-0.31-0.75
                                v-7.59v-7.58c0-0.29,0.12-0.56,0.31-0.75c0.19-0.19,0.46-0.31,0.75-0.31h15.69h15.69c0.29,0,0.56-0.12,0.75-0.31
                                s0.31-0.46,0.31-0.75v-11.64v-11.64c0-0.29-0.12-0.56-0.31-0.75c-0.19-0.19-0.46-0.31-0.75-0.31h-15.69h-15.69
                                c-0.29,0-0.56-0.12-0.75-0.31s-0.31-0.46-0.31-0.75v-7.61v-7.61c0-0.29,0.12-0.56,0.31-0.75S344.93,274.65,345.22,274.65z"/>
                            <path class="st3" d="M496.61,246.05l-21.3,0l-21.3,0c-0.59,0-0.96,0.41-1.04,0.88s0.13,0.97,0.69,1.17
                                c1.84,0.65,3.59,1.39,5.23,2.21c1.64,0.82,3.19,1.73,4.63,2.74c4.17,2.91,7.36,6.48,9.51,10.76c2.15,4.28,3.27,9.26,3.27,14.99
                                c0,7.35-3,13.61-7.25,18.48c-4.25,4.88-9.76,8.37-14.77,10.17c-0.08,0.03-0.14,0.08-0.19,0.14s-0.07,0.14-0.07,0.22
                                c0,0.08,0.02,0.15,0.06,0.21s0.1,0.11,0.17,0.14c4.19,1.79,7.48,4.56,10.13,8.16c2.65,3.6,4.66,8.02,6.3,13.09
                                c1.84,5.79,3.77,12.74,5.56,18.92c1.79,6.18,3.44,11.6,4.72,14.35c0.09,0.19,0.22,0.34,0.39,0.44c0.17,0.11,0.36,0.16,0.57,0.16
                                l7.34,0l7.34,0c7.05,0,15.05-0.39,22.77-2.24c7.71-1.85,15.14-5.17,21.04-11.03c7.37-7.32,11.07-13.8,12.94-20.88
                                s1.89-14.76,1.89-24.48c0-9.14-0.69-17.55-2.87-25.14c-2.18-7.59-5.85-14.36-11.8-20.23c-6.73-6.64-14.2-9.95-21.74-11.61
                                C511.29,246.05,503.66,246.05,496.61,246.05z M516.31,325.43c-0.93,2.1-2.03,3.88-3.31,5.35c-1.28,1.47-2.74,2.63-4.37,3.47
                                c-1.63,0.84-3.82,1.47-6.57,1.89c-2.75,0.42-6.05,0.63-9.91,0.63h-4h-4c-0.29,0-0.56-0.12-0.75-0.31
                                c-0.19-0.19-0.31-0.46-0.31-0.75v-31.05v-31.05c0-0.29,0.12-0.56,0.31-0.75c0.19-0.19,0.46-0.31,0.75-0.31h4.08h4.08
                                c4.79,0,8.9,0.58,12.33,1.73s6.18,2.87,8.25,5.17c2.07,2.3,3.62,5.58,4.65,9.84c1.03,4.26,1.55,9.5,1.55,15.73
                                c0,4.7-0.23,8.76-0.7,12.16C517.93,320.59,517.23,323.34,516.31,325.43z"/>
                            <path class="st3" d="M564.17,363.31h17.21h17.21c0.29,0,0.56-0.12,0.75-0.31c0.19-0.19,0.31-0.46,0.31-0.75V321.8v-40.46
                                c0-0.16-0.04-0.32-0.11-0.46c-0.07-0.14-0.17-0.27-0.3-0.37l-17.21-13.54l-17.21-13.54c-0.35-0.27-0.77-0.29-1.11-0.12
                                c-0.34,0.17-0.59,0.51-0.59,0.95v54v54c0,0.29,0.12,0.56,0.31,0.75S563.88,363.31,564.17,363.31z"/>
                            <path class="st3" d="M607.81,359.33v-0.02v-0.02c0.25-0.07,0.47-0.2,0.64-0.39c0.17-0.19,0.27-0.43,0.27-0.73
                                c0-0.22-0.04-0.4-0.11-0.56c-0.07-0.16-0.18-0.29-0.32-0.39c-0.13-0.1-0.32-0.19-0.55-0.25c-0.24-0.06-0.53-0.1-0.88-0.1
                                c-0.28,0-0.51,0.01-0.72,0.03c-0.21,0.02-0.4,0.05-0.6,0.08v2.29v2.29h0.37h0.37v-0.99v-0.99h0.26h0.26
                                c0.24,0,0.46,0.08,0.64,0.23c0.18,0.14,0.3,0.35,0.34,0.58c0.05,0.3,0.11,0.55,0.16,0.75c0.05,0.2,0.11,0.34,0.16,0.41h0.39h0.39
                                c-0.03-0.07-0.08-0.18-0.13-0.36s-0.1-0.42-0.16-0.78c-0.05-0.34-0.14-0.6-0.27-0.79C608.19,359.47,608.01,359.36,607.81,359.33z
                                M606.8,359.03h-0.27h-0.27v-0.78v-0.78c0.06-0.01,0.14-0.02,0.23-0.02s0.19-0.01,0.3-0.01c0.42,0,0.71,0.1,0.88,0.25
                                c0.18,0.15,0.25,0.35,0.25,0.56c0,0.28-0.13,0.48-0.33,0.6C607.4,358.98,607.11,359.03,606.8,359.03z"/>
                            <path class="st3" d="M607.07,355.07L607.07,355.07h-0.02c-1.14,0-2.17,0.46-2.92,1.2c-0.75,0.74-1.21,1.77-1.21,2.92
                                c0,1.16,0.46,2.19,1.21,2.93c0.75,0.74,1.78,1.2,2.92,1.2c1.15,0,2.18-0.45,2.92-1.2c0.74-0.74,1.2-1.77,1.2-2.93
                                c0-1.15-0.45-2.18-1.19-2.92C609.23,355.53,608.21,355.07,607.07,355.07z M607.07,362.67c-0.92,0-1.76-0.38-2.37-1.01
                                s-0.98-1.5-0.98-2.47c0-0.96,0.37-1.83,0.98-2.46c0.6-0.63,1.44-1.02,2.35-1.02h0.01h0.01c0.93,0,1.76,0.39,2.37,1.02
                                c0.6,0.63,0.98,1.5,0.98,2.47c0,0.97-0.37,1.85-0.98,2.47C608.83,362.3,607.99,362.69,607.07,362.67z"/>
                            <path class="st4" d="M599.64,271.09V259.1v-11.99c0-0.29-0.12-0.56-0.31-0.75c-0.19-0.19-0.46-0.31-0.75-0.31h-15.23h-15.23
                                c-0.5,0-0.86,0.32-1,0.71s-0.05,0.87,0.34,1.18l15.23,11.99l15.23,11.99c0.35,0.27,0.77,0.29,1.11,0.12
                                C599.38,271.88,599.64,271.53,599.64,271.09z"/>
                            <path class="st5" d="M262.53,280.36l-17.21-13.54l-17.21-13.54c-0.35-0.27-0.77-0.29-1.11-0.12c-0.34,0.17-0.59,0.51-0.59,0.95
                                v54.01v54.01c0,0.29,0.12,0.56,0.31,0.75c0.19,0.19,0.46,0.31,0.75,0.31h17.21h17.21c0.29,0,0.56-0.12,0.75-0.31
                                s0.31-0.46,0.31-0.75v-40.46v-40.46c0-0.16-0.04-0.32-0.11-0.46C262.76,280.59,262.66,280.46,262.53,280.36z"/>
                            <path class="st6" d="M231.41,245.89h15.23h15.23c0.29,0,0.56,0.12,0.75,0.31s0.31,0.46,0.31,0.75v11.99v11.99
                                c0,0.44-0.25,0.78-0.6,0.95s-0.77,0.15-1.11-0.12l-15.23-11.99l-15.23-11.99c-0.39-0.31-0.48-0.78-0.34-1.18
                                C230.55,246.21,230.91,245.89,231.41,245.89z"/>
                            <path class="st5" d="M203.73,259.15c-6.73-6.64-14.2-9.95-21.75-11.6c-7.55-1.65-15.18-1.65-22.23-1.65l-79.34,0.01l-79.34,0.01
                                c-0.29,0-0.56,0.12-0.75,0.31c-0.19,0.19-0.31,0.46-0.31,0.75v13.24v13.24c0,0.29,0.12,0.56,0.31,0.75s0.45,0.31,0.75,0.31
                                c2.85,0,11.04,0,19.24,0s16.39,0,19.24,0c0.29,0,0.56,0.12,0.75,0.31c0.19,0.19,0.31,0.46,0.31,0.75v43.27v43.27
                                c0,0.29,0.12,0.56,0.31,0.75c0.19,0.19,0.46,0.31,0.75,0.31l16.52,0.01l16.52,0.01c0.29,0,0.56-0.12,0.75-0.31
                                c0.19-0.19,0.31-0.46,0.31-0.75v-37.28v-37.28c-0.01-3.44,1.23-6.51,3.29-9.04c2.07-2.53,4.96-4.53,8.26-5.8
                                c0.08-0.01,0.16-0.02,0.23-0.04c0.08-0.01,0.16-0.03,0.23-0.05c0.03-0.01,0.05-0.01,0.08-0.02s0.05-0.01,0.08-0.02v-1.22v-1.22
                                c0-0.35,0.14-0.66,0.37-0.89c0.23-0.23,0.54-0.37,0.89-0.37h0.29h0.29c0.17,0,0.32-0.07,0.43-0.18c0.11-0.11,0.18-0.26,0.18-0.43
                                l0-0.96v-0.96c0-0.17-0.07-0.32-0.18-0.43c-0.11-0.11-0.27-0.18-0.44-0.18c-0.17,0-0.33-0.08-0.44-0.19
                                c-0.11-0.12-0.18-0.27-0.18-0.45l0-1.91v-1.91c0-0.18,0.08-0.33,0.2-0.44s0.29-0.17,0.47-0.18c0.16-0.01,0.3-0.08,0.41-0.19
                                c0.1-0.11,0.17-0.26,0.17-0.42v-0.36v-0.36c0-0.18,0.07-0.34,0.19-0.45s0.28-0.19,0.45-0.19h1.03h1.03h1.03h1.03
                                c0.18,0,0.34,0.07,0.45,0.19c0.12,0.12,0.19,0.28,0.19,0.45v0.36v0.36c0,0.16,0.06,0.31,0.17,0.42c0.1,0.11,0.25,0.18,0.41,0.19
                                c0.18,0.01,0.35,0.07,0.47,0.18c0.12,0.11,0.2,0.26,0.2,0.44v1.74l0,1.74c0,0.35-0.07,0.59-0.19,0.74
                                c-0.12,0.15-0.28,0.22-0.45,0.24c-0.17,0.02-0.32,0.08-0.43,0.19c-0.11,0.11-0.18,0.25-0.18,0.42l0,0.96l0,0.96
                                c0,0.17,0.07,0.32,0.18,0.43c0.11,0.11,0.26,0.18,0.43,0.18h0.29H97c0.35,0,0.66,0.14,0.89,0.37c0.23,0.23,0.37,0.54,0.37,0.89
                                v1.2v1.2c0.03,0.01,0.06,0.01,0.09,0.02s0.06,0.01,0.09,0.02c3.3,1.2,6.21,3.12,8.34,5.57c2.13,2.45,3.47,5.44,3.63,8.79v0.05
                                v0.05c0,0.09,0.01,0.17,0.01,0.26c0.01,0.09,0.01,0.17,0.01,0.26l-0.01,37.28l-0.01,37.28c0,0.29,0.12,0.56,0.31,0.75
                                c0.19,0.19,0.46,0.31,0.75,0.31l24.15,0l24.15,0c7.05,0,15.05-0.39,22.77-2.24c7.72-1.85,15.14-5.17,21.04-11.03
                                c7.37-7.32,11.07-13.8,12.94-20.88c1.87-7.08,1.89-14.76,1.89-24.49c0-9.14-0.69-17.55-2.87-25.15
                                C213.35,271.79,209.68,265.02,203.73,259.15z M109.16,265.76c0,0.32-0.07,0.64-0.33,0.88c-0.26,0.24-0.71,0.4-1.48,0.38
                                c-1.54-0.03-3.23-0.25-4.69-0.5c-1.46-0.25-2.7-0.53-3.36-0.69c-0.15-0.04-0.28-0.12-0.37-0.24c-0.09-0.12-0.14-0.26-0.14-0.42
                                l0-0.97l0-0.97l0-0.97l0-0.97c0-0.16,0.05-0.3,0.14-0.42c0.09-0.12,0.22-0.2,0.37-0.24c0.66-0.16,1.9-0.44,3.36-0.69
                                c1.46-0.25,3.14-0.47,4.69-0.5c0.77-0.02,1.22,0.14,1.48,0.38c0.26,0.24,0.33,0.57,0.33,0.88l0,1.26l0,1.26l0,1.26L109.16,265.76z
                                M179.46,325.28c-0.93,2.1-2.03,3.88-3.31,5.35c-1.28,1.47-2.74,2.63-4.37,3.47c-1.63,0.84-3.82,1.47-6.57,1.89
                                c-2.75,0.42-6.05,0.63-9.91,0.63h-4h-4c-0.29,0-0.56-0.12-0.75-0.31c-0.19-0.19-0.31-0.46-0.31-0.75v-31.05v-31.05
                                c0-0.29,0.12-0.56,0.31-0.75c0.19-0.19,0.46-0.31,0.75-0.31h4.08h4.08c4.79,0,8.9,0.58,12.33,1.73s6.18,2.87,8.25,5.17
                                c2.07,2.3,3.62,5.58,4.65,9.84s1.55,9.51,1.55,15.73c0,4.71-0.23,8.76-0.7,12.16C181.08,320.44,180.38,323.19,179.46,325.28z"/>
                            <g>
                                <g>
                                    <path class="st5" d="M270.34,354.9c1.14,0,2.17,0.46,2.91,1.2c0.74,0.74,1.19,1.77,1.19,2.92c0,1.16-0.45,2.19-1.2,2.93
                                        s-1.77,1.2-2.92,1.2c-1.14,0-2.17-0.45-2.92-1.2s-1.21-1.77-1.21-2.93c0-1.15,0.46-2.18,1.21-2.92c0.75-0.74,1.78-1.2,2.92-1.2
                                        H270.34L270.34,354.9z M270.32,355.54c-0.92,0-1.75,0.39-2.35,1.02c-0.6,0.63-0.98,1.5-0.98,2.46c0,0.98,0.37,1.85,0.98,2.48
                                        c0.61,0.63,1.44,1.01,2.37,1.01c0.93,0.01,1.77-0.37,2.37-1c0.6-0.63,0.98-1.5,0.98-2.47c0-0.97-0.37-1.84-0.98-2.47
                                        c-0.61-0.63-1.44-1.02-2.37-1.02H270.32L270.32,355.54z M269.54,361.4h-0.37h-0.37v-2.29v-2.29c0.2-0.03,0.39-0.06,0.6-0.08
                                        c0.21-0.02,0.44-0.03,0.72-0.03c0.35,0,0.65,0.04,0.88,0.1c0.24,0.06,0.42,0.15,0.55,0.25c0.14,0.1,0.25,0.23,0.32,0.39
                                        c0.07,0.16,0.11,0.34,0.11,0.56c0,0.3-0.1,0.54-0.27,0.73c-0.17,0.19-0.39,0.32-0.64,0.39v0.02v0.02
                                        c0.21,0.04,0.38,0.14,0.51,0.33c0.13,0.18,0.22,0.45,0.27,0.79c0.06,0.35,0.11,0.6,0.16,0.78c0.05,0.18,0.09,0.28,0.13,0.36
                                        h-0.39h-0.39c-0.05-0.07-0.1-0.21-0.16-0.41c-0.05-0.2-0.11-0.45-0.16-0.75c-0.05-0.3-0.15-0.5-0.31-0.62
                                        c-0.16-0.13-0.38-0.18-0.68-0.18h-0.26h-0.26v0.99V361.4z M269.54,358.86h0.27h0.27c0.31,0,0.6-0.05,0.8-0.18
                                        c0.21-0.12,0.33-0.32,0.33-0.6c0-0.2-0.07-0.41-0.25-0.56c-0.18-0.15-0.46-0.25-0.88-0.25c-0.11,0-0.22,0-0.3,0.01
                                        c-0.09,0.01-0.16,0.02-0.23,0.02v0.78V358.86z"/>
                                </g>
                            </g>
                            <path class="st7" d="M99.95,72.02c-2.99-0.45-6.01-0.97-8.97-1.72c-2.96-0.75-5.85-1.73-8.57-3.09c-2.77-1.38-5.5-3.27-7.44-5.65
                                s-3.08-5.27-2.67-8.66c0.47-3.89,3.99-6.6,8.23-8.37c4.24-1.77,9.19-2.59,12.51-2.71c0.93-0.03,14.15-0.05,33.27-0.06
                                s44.13-0.01,68.63-0.01c0.92,0,1.59-0.58,1.84-1.31c0.25-0.73,0.09-1.59-0.64-2.17l-23.98-18.86L148.18,0.55
                                c-0.18-0.14-0.37-0.24-0.58-0.32c-0.21-0.07-0.43-0.11-0.65-0.11c-13.05,0.02-25.34,0.04-34.38,0.06S97.76,0.21,97.76,0.21l0,0
                                l0,0C56.03,0.16,33.65,13.71,21.7,27.27S8.23,54.42,8.23,54.42c-0.83,9.52,0.67,18.56,4.24,26.71s9.22,15.39,16.68,21.31
                                c6.88,5.46,14.62,9.71,22.75,13.11s16.65,5.96,25.09,8.05c3.57,0.88,7.17,1.68,10.78,2.4c3.61,0.73,7.23,1.39,10.86,2
                                c2.18,0.37,4.45,0.9,6.72,1.37c2.27,0.47,4.56,0.87,6.76,1c1.32,0.08,2.41-0.25,3.45-0.76c1.04-0.51,2.02-1.21,3.11-1.88
                                c4.1-2.49,8.7-3.37,13.35-3.24c4.65,0.13,9.35,1.28,13.64,2.85c3.03,1.11,5.98,2.47,8.81,4.02c2.84,1.55,5.57,3.28,8.19,5.14
                                c0.31,0.22,0.62,0.45,0.92,0.68c0.3,0.23,0.61,0.47,0.91,0.7c0.7,0.54,1.68,1.35,2.06,2.02c0.38,0.67,0.15,1.2-1.57,1.18
                                c-4.88-0.05-9.58,0.84-14.05,2.42c-4.47,1.58-8.72,3.86-12.7,6.57c-2.95,2.01-6.08,4.28-9.43,5.89c-3.35,1.61-6.9,2.56-10.7,1.94
                                c-2.29-0.37-4.02-0.83-5.19-1.14s-1.78-0.48-1.8-0.27c-0.19,1.93-0.53,3.87-1.01,5.93c-0.48,2.06-1.11,4.22-1.9,6.57
                                c-0.07,0.21-0.15,0.41-0.26,0.61c-0.1,0.2-0.22,0.38-0.37,0.55c-0.87,1.02-1.92,1.79-3.03,2.45s-2.31,1.23-3.45,1.86
                                c-1.13,0.62-2.23,1.28-3.3,1.99c-1.08,0.7-2.12,1.45-3.14,2.23c-2.04,1.56-3.96,3.28-5.74,5.13c-1.78,1.85-3.43,3.84-4.91,5.93
                                c-0.69,0.98-1.35,1.99-1.97,3.02c-0.62,1.03-1.2,2.09-1.74,3.16c-0.38,0.75-0.74,1.52-1.08,2.29c-0.34,0.77-0.66,1.55-0.95,2.34
                                c-0.06,0.16-0.16,0.3-0.28,0.42c-0.12,0.11-0.27,0.19-0.44,0.23c-0.31,0.06-0.55-0.05-0.73-0.25c-0.18-0.2-0.31-0.47-0.41-0.72
                                c-0.24-0.59-0.47-1.17-0.68-1.76c-0.21-0.59-0.41-1.18-0.59-1.79c-0.27-0.94-0.51-1.89-0.73-2.84s-0.43-1.91-0.65-2.86
                                c-1.47-6.27-2.32-13.43-1.49-20.22c0.83-6.79,3.32-13.22,8.55-18.03c0.17-0.15,0.34-0.29,0.52-0.43s0.36-0.28,0.53-0.42
                                c0.71-0.57,0.85-1.43,0.59-2.15c-0.26-0.72-0.92-1.29-1.83-1.3c0,0-16.27-0.08-34.24-0.17s-37.64-0.19-44.43-0.22
                                c-0.63,0-1.18,0.29-1.53,0.74c-0.35,0.45-0.51,1.05-0.35,1.66c1.47,5.75,3.49,11.35,6.07,16.67s5.73,10.37,9.47,14.99
                                c3.37,4.17,7.19,7.95,11.33,11.32c4.15,3.37,8.63,6.34,13.34,8.9c4.19,2.27,8.54,4.21,13,5.86c4.46,1.65,9.04,3,13.67,4.1
                                c8.89,2.1,18.02,3.24,27.18,3.59s18.37-0.1,27.42-1.18c8.67-1.04,17.37-2.68,25.74-5.27c8.37-2.6,16.4-6.16,23.74-11.06
                                c0.04-0.02,0.07-0.05,0.11-0.07c0.04-0.02,0.07-0.05,0.11-0.07c3.56-2.4,6.9-5.1,9.98-8.08c3.07-2.98,5.87-6.24,8.33-9.76
                                c1.95-2.8,3.69-5.74,5.19-8.8c1.5-3.06,2.78-6.23,3.81-9.48c0.32-1,1.39-4.07,2.39-8.22c1-4.15,1.92-9.38,1.93-14.68
                                c-0.32-5.33-0.99-8.83-1.58-11c-0.59-2.17-1.1-3-1.1-3c-6.63-21.73-31.69-35.03-55.09-42.9S99.95,72.02,99.95,72.02z"/>
                            <path class="st7" d="M498,63.48l-31.7-24.94l-31.7-24.94c-0.64-0.5-1.43-0.53-2.05-0.22c-0.63,0.3-1.1,0.94-1.1,1.75v99.46v99.46
                                c0,0.54,0.22,1.02,0.57,1.38c0.35,0.35,0.84,0.57,1.38,0.57h31.7h31.7c0.54,0,1.02-0.22,1.38-0.57c0.35-0.35,0.57-0.84,0.57-1.38
                                v-74.52V65.01c0-0.3-0.07-0.59-0.2-0.85C498.42,63.9,498.23,63.66,498,63.48z"/>
                            <g>
                                <path class="st7" d="M512.42,200.82c2.1,0,3.99,0.84,5.35,2.21c1.36,1.37,2.2,3.27,2.2,5.38c0,2.13-0.84,4.03-2.2,5.39
                                    s-3.26,2.2-5.38,2.2c-2.1,0-4-0.83-5.38-2.2s-2.24-3.27-2.24-5.39c0-2.11,0.86-4.01,2.24-5.38s3.29-2.21,5.38-2.21h0.01H512.42z
                                    M512.39,202c-1.69,0-3.22,0.72-4.33,1.87c-1.11,1.16-1.8,2.76-1.8,4.53c0,1.8,0.69,3.4,1.81,4.56c1.12,1.16,2.66,1.86,4.36,1.86
                                    c1.71,0.02,3.25-0.69,4.37-1.84c1.11-1.16,1.8-2.77,1.8-4.56c0-1.78-0.69-3.39-1.8-4.55s-2.66-1.88-4.37-1.88h-0.01H512.39z
                                    M510.96,212.8h-0.68h-0.68v-4.22v-4.22c0.36-0.05,0.71-0.1,1.1-0.14c0.39-0.04,0.81-0.06,1.33-0.06c0.65,0,1.19,0.07,1.63,0.18
                                    s0.78,0.27,1.02,0.46c0.26,0.19,0.46,0.42,0.6,0.71c0.13,0.29,0.2,0.63,0.2,1.04c0,0.55-0.19,1-0.5,1.35s-0.73,0.58-1.18,0.71
                                    v0.03v0.03c0.38,0.07,0.69,0.27,0.94,0.61c0.24,0.34,0.41,0.82,0.5,1.45c0.11,0.65,0.21,1.11,0.3,1.43
                                    c0.09,0.32,0.17,0.52,0.24,0.65h-0.71h-0.71c-0.09-0.13-0.19-0.39-0.29-0.75c-0.1-0.36-0.2-0.83-0.3-1.38
                                    c-0.08-0.43-0.31-0.81-0.63-1.07s-0.74-0.42-1.18-0.42l-0.49,0h-0.49v1.81V212.8z M510.96,208.11h0.49h0.49
                                    c0.57,0,1.1-0.1,1.48-0.33c0.38-0.23,0.61-0.59,0.61-1.1c0-0.38-0.13-0.75-0.46-1.03s-0.85-0.46-1.63-0.46
                                    c-0.21,0-0.4,0.01-0.56,0.02s-0.3,0.03-0.42,0.05v1.43V208.11z"/>
                            </g>
                            <path class="st6" d="M440.69,0h28.05h28.05c0.54,0,1.02,0.22,1.38,0.57s0.57,0.84,0.57,1.38v22.09v22.09
                                c0,0.81-0.47,1.45-1.1,1.75c-0.63,0.3-1.41,0.28-2.05-0.22l-28.05-22.09L439.48,3.47c-0.73-0.57-0.89-1.44-0.63-2.17
                                C439.1,0.58,439.76,0,440.69,0z"/>
                            <path class="st5" d="M283.97,214.05l-0.02-79.71l-0.02-79.71c0-0.54-0.22-1.02-0.57-1.38s-0.84-0.57-1.38-0.57h-31.04H219.9
                                c-0.54,0-1.02,0.22-1.38,0.57c-0.35,0.35-0.57,0.84-0.57,1.38v79.71v79.71c0,0.54,0.22,1.02,0.57,1.38
                                c0.35,0.35,0.84,0.57,1.38,0.57l31.07,0l31.07,0c0.54,0,1.02-0.22,1.38-0.57C283.76,215.08,283.98,214.59,283.97,214.05z"/>
                            <path class="st7" d="M300.92,41.74c8.82,0,16.4,1.06,22.71,3.18c6.32,2.12,11.38,5.29,15.19,9.53c1.96,2.18,3.66,5.31,5.12,9.15
                                s2.66,8.39,3.61,13.4c0.09,0.46,0.33,0.86,0.67,1.14c0.34,0.28,0.77,0.45,1.24,0.45l31.68,0l31.68,0c0.6,0,1.12-0.27,1.48-0.68
                                c0.36-0.41,0.54-0.97,0.45-1.56c-1.56-10.02-4.13-19.38-8.13-28.05c-4.01-8.67-9.46-16.64-16.79-23.87
                                c-12.4-12.22-26.15-18.33-40.05-21.38S321.84,0,308.85,0l-69.12,0.01L170.6,0.02c-0.92,0-1.59,0.58-1.84,1.31
                                s-0.09,1.59,0.63,2.17l24.01,18.91l24.01,18.91c0.17,0.14,0.36,0.24,0.57,0.31c0.2,0.07,0.42,0.11,0.64,0.11h41.15H300.92z"/>
                            <path class="st5" d="M351.45,89.53c-0.57,0-1.08,0.24-1.43,0.63c-0.35,0.39-0.55,0.91-0.5,1.48c0.24,2.76,0.42,5.57,0.55,8.41
                                c0.12,2.84,0.18,5.72,0.18,8.59c0,8.67-0.43,16.13-1.28,22.4c-0.85,6.26-2.13,11.33-3.83,15.19c-1.71,3.86-3.74,7.15-6.1,9.86
                                c-2.36,2.71-5.04,4.84-8.05,6.39c-3.01,1.55-7.04,2.71-12.11,3.48c-5.06,0.77-11.15,1.16-18.26,1.16h-1.88h-1.88
                                c-0.54,0-1.02,0.22-1.38,0.57c-0.35,0.35-0.57,0.84-0.57,1.38v22.49v22.49c0,0.54,0.22,1.02,0.57,1.38
                                c0.35,0.35,0.84,0.57,1.38,0.57l6,0l6,0c12.98,0,27.73-0.71,41.94-4.12c14.21-3.41,27.89-9.52,38.75-20.32
                                c13.57-13.49,20.4-25.42,23.83-38.46s3.48-27.19,3.48-45.1c0-2.85-0.04-5.67-0.12-8.45c-0.08-2.78-0.21-5.51-0.38-8.21
                                c-0.03-0.51-0.26-0.97-0.62-1.29s-0.82-0.52-1.34-0.52l-31.49,0L351.45,89.53z"/>
                            <g>
                                <path class="st5" d="M612.84,131.57c0,8.38-1.56,15.41-4.66,21.06c-3.11,5.66-7.56,9.92-13.36,12.78
                                    c-5.8,2.87-12.82,4.3-21.06,4.3H552.9V94.89h23.06c7.55,0,14.08,1.4,19.6,4.19c5.52,2.8,9.78,6.9,12.79,12.31
                                    C611.34,116.81,612.84,123.54,612.84,131.57z M602.89,131.89c0-6.64-1.1-12.09-3.3-16.35c-2.2-4.26-5.42-7.42-9.64-9.48
                                    c-4.23-2.06-9.35-3.09-15.35-3.09h-12.26v58.69h10.17c10.13,0,17.73-2.5,22.79-7.49C600.35,149.16,602.89,141.74,602.89,131.89z"
                                    />
                                <path class="st5" d="M650.47,169.72h-27.04v-5.45l8.8-1.99v-59.84l-8.8-2.1v-5.45h27.04v5.45l-8.8,2.1v59.84l8.8,1.99V169.72z"/>
                                <path class="st5" d="M717.64,94.89l-26.72,74.82h-9.43l-26.72-74.82h9.85l16.87,48c0.77,2.03,1.43,3.98,1.99,5.87
                                    c0.56,1.89,1.06,3.69,1.52,5.4c0.45,1.71,0.85,3.41,1.21,5.08c0.35-1.68,0.75-3.39,1.2-5.14c0.45-1.75,0.98-3.56,1.57-5.45
                                    c0.59-1.89,1.24-3.88,1.94-5.97l16.77-47.79H717.64z"/>
                                <path class="st5" d="M748.87,169.72h-27.04v-5.45l8.8-1.99v-59.84l-8.8-2.1v-5.45h27.04v5.45l-8.8,2.1v59.84l8.8,1.99V169.72z"/>
                                <path class="st5" d="M822.64,169.72h-11l-40.03-62.14h-0.42c0.14,1.61,0.26,3.51,0.37,5.71c0.11,2.2,0.21,4.54,0.31,7.02
                                    c0.11,2.48,0.16,5.01,0.16,7.6v41.81h-8.7V94.89h10.9l39.93,61.93h0.42c-0.07-1.12-0.16-2.79-0.26-5.03
                                    c-0.1-2.23-0.21-4.68-0.31-7.34c-0.1-2.65-0.16-5.1-0.16-7.34V94.89h8.8V169.72z"/>
                                <path class="st5" d="M875.46,130.21h25.88v36.68c-4.05,1.33-8.14,2.31-12.26,2.93c-4.12,0.63-8.8,0.94-14.04,0.94
                                    c-7.76,0-14.29-1.55-19.6-4.66c-5.31-3.11-9.35-7.55-12.1-13.31c-2.76-5.76-4.14-12.59-4.14-20.49c0-7.82,1.54-14.6,4.61-20.33
                                    c3.07-5.73,7.49-10.18,13.26-13.36c5.76-3.18,12.73-4.77,20.91-4.77c4.19,0,8.16,0.38,11.89,1.15c3.74,0.77,7.21,1.85,10.43,3.25
                                    l-3.56,8.17c-2.66-1.19-5.64-2.2-8.96-3.04c-3.32-0.84-6.76-1.26-10.32-1.26c-5.94,0-11.04,1.22-15.3,3.67
                                    c-4.26,2.45-7.51,5.92-9.75,10.43c-2.24,4.51-3.35,9.87-3.35,16.09c0,5.94,0.96,11.2,2.88,15.77c1.92,4.58,4.94,8.14,9.06,10.69
                                    c4.12,2.55,9.54,3.82,16.24,3.82c3.28,0,6.08-0.17,8.38-0.52c2.31-0.35,4.4-0.77,6.29-1.26v-22.22h-16.45V130.21z"/>
                            </g>
                            <g>
                                <path class="st5" d="M602.8,58.9c0,4.72-1.14,8.71-3.43,11.99c-2.29,3.28-5.55,5.8-9.79,7.57c-4.24,1.77-9.24,2.65-14.99,2.65
                                    c-2.95,0-5.77-0.15-8.46-0.44c-2.69-0.3-5.14-0.72-7.36-1.27c-2.21-0.55-4.17-1.23-5.86-2.04v-9.5c2.66,1.18,5.96,2.25,9.9,3.2
                                    c3.95,0.96,8.02,1.44,12.23,1.44c3.91,0,7.19-0.51,9.85-1.55c2.66-1.03,4.65-2.5,5.98-4.42c1.33-1.92,1.99-4.16,1.99-6.74
                                    c0-2.58-0.55-4.75-1.66-6.52s-3.01-3.41-5.7-4.92c-2.69-1.51-6.44-3.11-11.23-4.81c-3.39-1.25-6.36-2.6-8.91-4.03
                                    c-2.55-1.44-4.67-3.08-6.36-4.92c-1.7-1.84-2.97-3.94-3.82-6.3c-0.85-2.36-1.27-5.08-1.27-8.18c0-4.2,1.07-7.79,3.21-10.77
                                    c2.14-2.98,5.11-5.29,8.91-6.91c3.8-1.62,8.17-2.43,13.11-2.43c4.35,0,8.33,0.41,11.95,1.22c3.61,0.81,6.9,1.88,9.85,3.2
                                    l-3.1,8.51c-2.73-1.18-5.7-2.17-8.91-2.98c-3.21-0.81-6.55-1.22-10.01-1.22c-3.32,0-6.09,0.48-8.3,1.44
                                    c-2.21,0.96-3.87,2.3-4.98,4.03c-1.11,1.73-1.66,3.74-1.66,6.02c0,2.65,0.55,4.86,1.66,6.63s2.91,3.35,5.42,4.75
                                    c2.51,1.4,5.9,2.91,10.18,4.53c4.65,1.7,8.58,3.52,11.78,5.47c3.21,1.95,5.64,4.29,7.3,7.02C601.97,51.35,602.8,54.78,602.8,58.9
                                    z"/>
                                <path class="st5" d="M652.59,8.73c-4.2,0-8,0.72-11.4,2.15c-3.39,1.44-6.27,3.54-8.63,6.3c-2.36,2.76-4.17,6.12-5.42,10.06
                                    c-1.26,3.94-1.88,8.38-1.88,13.32c0,6.48,1.01,12.12,3.04,16.91c2.03,4.79,5.05,8.47,9.07,11.05c4.02,2.58,9.05,3.87,15.1,3.87
                                    c3.47,0,6.75-0.29,9.85-0.88c3.1-0.59,6.12-1.33,9.07-2.21v8.62c-2.95,1.1-5.99,1.92-9.13,2.43c-3.14,0.51-6.88,0.77-11.23,0.77
                                    c-8.04,0-14.75-1.66-20.14-4.97c-5.39-3.31-9.42-8.03-12.12-14.15c-2.69-6.11-4.04-13.3-4.04-21.55c0-5.97,0.83-11.42,2.49-16.36
                                    c1.66-4.94,4.09-9.21,7.3-12.82c3.21-3.61,7.17-6.39,11.89-8.34C641.16,0.98,646.58,0,652.7,0c4.06,0,7.97,0.41,11.73,1.22
                                    c3.76,0.81,7.12,1.95,10.07,3.43l-3.98,8.4c-2.43-1.1-5.15-2.1-8.13-2.98S656.13,8.73,652.59,8.73z"/>
                                <path class="st5" d="M748.75,52.16c0,5.45-1.11,10.37-3.32,14.75c-2.21,4.38-5.59,7.85-10.12,10.39
                                    c-4.54,2.54-10.31,3.81-17.32,3.81c-9.81,0-17.28-2.67-22.41-8.01c-5.13-5.34-7.69-12.39-7.69-21.16V1.1h9.96v51.17
                                    c0,6.41,1.72,11.38,5.15,14.92c3.43,3.54,8.61,5.3,15.55,5.3c4.79,0,8.69-0.87,11.67-2.6c2.99-1.73,5.18-4.13,6.58-7.18
                                    c1.4-3.06,2.1-6.58,2.1-10.55V1.1h9.85V52.16z"/>
                                <path class="st5" d="M792.12,1.1c9.88,0,17.35,1.46,22.41,4.37c5.05,2.91,7.58,7.98,7.58,15.2c0,3.09-0.57,5.84-1.72,8.23
                                    c-1.14,2.4-2.84,4.38-5.09,5.97c-2.25,1.59-5,2.67-8.24,3.26v0.55c3.32,0.52,6.32,1.47,9.02,2.87c2.69,1.4,4.81,3.39,6.36,5.97
                                    c1.55,2.58,2.32,5.93,2.32,10.06c0,4.79-1.11,8.86-3.32,12.21c-2.21,3.35-5.37,5.9-9.46,7.63c-4.09,1.73-8.98,2.6-14.66,2.6
                                    h-27.77V1.1H792.12z M794.11,34.7c6.79,0,11.43-1.09,13.94-3.26c2.51-2.17,3.76-5.4,3.76-9.67c0-4.35-1.53-7.46-4.59-9.34
                                    c-3.06-1.88-7.95-2.82-14.66-2.82h-13.06V34.7H794.11z M779.51,42.99v28.62h15.93c7.01,0,11.88-1.36,14.61-4.09
                                    c2.73-2.73,4.09-6.3,4.09-10.72c0-2.8-0.61-5.23-1.83-7.29c-1.22-2.06-3.25-3.66-6.09-4.81c-2.84-1.14-6.69-1.71-11.56-1.71
                                    H779.51z"/>
                                <path class="st5" d="M891.04,80.01l-9.52-24.42h-31.31l-9.41,24.42h-10.07l30.87-79.24h8.96l30.76,79.24H891.04z M869.69,22.88
                                    c-0.22-0.59-0.59-1.66-1.11-3.2c-0.52-1.55-1.01-3.15-1.49-4.81c-0.48-1.66-0.87-2.93-1.16-3.81c-0.37,1.47-0.76,2.97-1.16,4.48
                                    c-0.41,1.51-0.81,2.89-1.22,4.14c-0.41,1.25-0.76,2.32-1.05,3.2l-8.96,23.87h25.01L869.69,22.88z"/>
                            </g>
                            <g>
                                <path class="st5" d="M565.2,215.56h-12.3v-2.75l3.78-1.11v-23.91l-3.78-1.15v-2.75h12.3v2.75l-3.73,1.15v23.91l3.73,1.11V215.56z
                                    "/>
                                <path class="st5" d="M597.18,215.56h-5.82l-16.03-25.6h-0.18c0.06,0.77,0.11,1.63,0.16,2.57c0.04,0.95,0.09,1.94,0.13,2.97
                                    c0.04,1.04,0.07,2.1,0.07,3.19v16.86h-4.35v-31.68h5.77l15.99,25.47h0.13c-0.03-0.62-0.06-1.4-0.09-2.35
                                    c-0.03-0.95-0.07-1.96-0.11-3.04c-0.04-1.08-0.08-2.09-0.11-3.04v-17.04h4.44V215.56z"/>
                                <path class="st5" d="M616.28,215.56h-4.8V188h-9.51v-4.13h23.81V188h-9.51V215.56z"/>
                                <path class="st5" d="M648.48,215.56h-17.86v-31.68h17.86v4.08h-13.06v9.05h12.26v4.04h-12.26v10.38h13.06V215.56z"/>
                                <path class="st5" d="M664.25,183.88c2.72,0,4.96,0.34,6.71,1.02c1.75,0.68,3.05,1.69,3.91,3.04c0.86,1.35,1.29,3.04,1.29,5.08
                                    c0,1.63-0.3,2.99-0.91,4.1c-0.61,1.11-1.38,2.01-2.33,2.71c-0.95,0.7-1.94,1.25-2.98,1.66l8.88,14.06h-5.51l-7.64-12.82h-5.51
                                    v12.82h-4.8v-31.68H664.25z M663.94,187.96h-3.78v10.78h4c2.46,0,4.25-0.47,5.37-1.4c1.13-0.93,1.69-2.31,1.69-4.15
                                    c0-1.27-0.26-2.28-0.78-3.04c-0.52-0.75-1.32-1.31-2.4-1.66C666.97,188.14,665.6,187.96,663.94,187.96z"/>
                                <path class="st5" d="M709.51,215.56h-5.82l-16.03-25.6h-0.18c0.06,0.77,0.11,1.63,0.16,2.57c0.04,0.95,0.09,1.94,0.13,2.97
                                    c0.04,1.04,0.07,2.1,0.07,3.19v16.86h-4.35v-31.68h5.77l15.99,25.47h0.13c-0.03-0.62-0.06-1.4-0.09-2.35
                                    c-0.03-0.95-0.07-1.96-0.11-3.04c-0.04-1.08-0.08-2.09-0.11-3.04v-17.04h4.44V215.56z"/>
                                <path class="st5" d="M737.72,215.56l-3.38-9.14h-12.26l-3.38,9.14h-4.97l12.04-31.81h4.97l11.99,31.81H737.72z M729.81,193.33
                                    c-0.09-0.33-0.24-0.82-0.47-1.49c-0.22-0.67-0.44-1.35-0.64-2.04c-0.21-0.69-0.37-1.26-0.49-1.71c-0.15,0.59-0.32,1.24-0.51,1.93
                                    c-0.19,0.7-0.38,1.33-0.55,1.91c-0.18,0.58-0.33,1.04-0.44,1.4l-3.2,8.92h9.5L729.81,193.33z"/>
                                <path class="st5" d="M754.55,215.56h-4.8V188h-9.51v-4.13h23.81V188h-9.51V215.56z"/>
                                <path class="st5" d="M778.67,215.56h-12.3v-2.75l3.78-1.11v-23.91l-3.78-1.15v-2.75h12.3v2.75l-3.73,1.15v23.91l3.73,1.11V215.56
                                    z"/>
                                <path class="st5" d="M812.6,199.67c0,2.46-0.3,4.68-0.91,6.68c-0.61,2-1.53,3.72-2.75,5.17c-1.23,1.45-2.77,2.56-4.62,3.33
                                    c-1.85,0.77-4.01,1.15-6.46,1.15c-2.52,0-4.7-0.39-6.55-1.18c-1.85-0.78-3.39-1.89-4.62-3.33c-1.23-1.43-2.14-3.16-2.73-5.17
                                    c-0.59-2.01-0.89-4.24-0.89-6.7c0-3.25,0.53-6.09,1.6-8.52s2.7-4.32,4.91-5.68c2.21-1.36,4.98-2.04,8.33-2.04
                                    c3.26,0,5.97,0.67,8.15,2.02c2.18,1.35,3.81,3.24,4.91,5.68C812.05,193.53,812.6,196.39,812.6,199.67z M788.13,199.67
                                    c0,2.54,0.34,4.73,1.02,6.54c0.68,1.82,1.75,3.22,3.2,4.21c1.45,0.99,3.29,1.49,5.51,1.49c2.25,0,4.09-0.49,5.51-1.49
                                    c1.42-0.99,2.47-2.4,3.15-4.21c0.68-1.82,1.02-4,1.02-6.54c0-3.82-0.76-6.79-2.29-8.94c-1.53-2.14-3.98-3.22-7.35-3.22
                                    c-2.25,0-4.1,0.49-5.55,1.46c-1.45,0.98-2.52,2.37-3.2,4.17C788.47,194.96,788.13,197.13,788.13,199.67z"/>
                                <path class="st5" d="M845.52,215.56h-5.82l-16.03-25.6h-0.18c0.06,0.77,0.11,1.63,0.16,2.57c0.04,0.95,0.09,1.94,0.13,2.97
                                    c0.04,1.04,0.07,2.1,0.07,3.19v16.86h-4.35v-31.68h5.77l15.99,25.47h0.13c-0.03-0.62-0.06-1.4-0.09-2.35
                                    c-0.03-0.95-0.07-1.96-0.11-3.04c-0.04-1.08-0.08-2.09-0.11-3.04v-17.04h4.44V215.56z"/>
                                <path class="st5" d="M873.72,215.56l-3.38-9.14h-12.26l-3.38,9.14h-4.97l12.04-31.81h4.97l11.99,31.81H873.72z M865.81,193.33
                                    c-0.09-0.33-0.24-0.82-0.47-1.49c-0.22-0.67-0.44-1.35-0.64-2.04c-0.21-0.69-0.37-1.26-0.49-1.71c-0.15,0.59-0.32,1.24-0.51,1.93
                                    c-0.19,0.7-0.38,1.33-0.55,1.91c-0.18,0.58-0.33,1.04-0.44,1.4l-3.2,8.92h9.5L865.81,193.33z"/>
                                <path class="st5" d="M883,215.56v-31.68h4.8v27.55h13.55v4.13H883z"/>
                            </g>
                        </g>
                    </g>
                    </svg>
                </figure>
            </div>  
        </div>
        <!-- <p>Certificadora Internacional:</p>        
        <div>
            <figure>
               <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"	 viewBox="0 0 912.82 363.31" style="enable-background:new 0 0 912.82 363.31;" xml:space="preserve"><g id="Layer_1">
</g>
                <g id="Layer_2">
                    <g>
                        <g>
                            <g>
                                <path class="st0" d="M908.73,354.78c1.14,0,2.16,0.46,2.9,1.2s1.19,1.77,1.19,2.91c0,1.15-0.45,2.18-1.19,2.92
                                    c-0.74,0.74-1.77,1.19-2.91,1.19c-1.14,0-2.17-0.45-2.92-1.19c-0.75-0.74-1.21-1.77-1.21-2.92c0-1.14,0.46-2.17,1.21-2.91
                                    s1.78-1.2,2.92-1.2H908.73L908.73,354.78z M908.71,355.42c-0.91,0-1.75,0.39-2.35,1.02c-0.6,0.63-0.98,1.5-0.98,2.46
                                    c0,0.97,0.37,1.84,0.98,2.47c0.6,0.63,1.44,1.01,2.36,1.01c0.93,0.01,1.76-0.37,2.37-1s0.98-1.5,0.98-2.47
                                    c0-0.97-0.37-1.84-0.98-2.47s-1.44-1.02-2.37-1.02H908.71L908.71,355.42z M907.93,361.28h-0.37h-0.37v-2.29v-2.29
                                    c0.2-0.03,0.39-0.06,0.6-0.08s0.44-0.03,0.72-0.03c0.35,0,0.64,0.04,0.88,0.1c0.24,0.06,0.42,0.15,0.55,0.25
                                    c0.14,0.1,0.25,0.23,0.32,0.39c0.07,0.16,0.11,0.34,0.11,0.56c0,0.3-0.1,0.54-0.27,0.73c-0.17,0.19-0.39,0.31-0.64,0.39v0.02
                                    v0.02c0.21,0.04,0.38,0.14,0.51,0.33c0.13,0.18,0.22,0.44,0.27,0.78c0.06,0.35,0.11,0.6,0.16,0.78
                                    c0.05,0.18,0.09,0.28,0.13,0.35h-0.39h-0.39c-0.05-0.07-0.1-0.22-0.16-0.42c-0.05-0.2-0.11-0.46-0.17-0.77
                                    c-0.04-0.22-0.16-0.42-0.32-0.56c-0.16-0.14-0.37-0.22-0.6-0.22h-0.29h-0.29v0.98V361.28z M907.93,358.74h0.26h0.26
                                    c0.31,0,0.59-0.05,0.8-0.18c0.21-0.12,0.33-0.32,0.33-0.6c0-0.2-0.07-0.41-0.25-0.56c-0.18-0.15-0.46-0.25-0.88-0.25
                                    c-0.11,0-0.22,0-0.3,0.01c-0.09,0.01-0.16,0.02-0.23,0.02v0.77V358.74z"/>
                            </g>
                        </g>
                        <g>
                            <path class="st1" d="M869.66,245.95h15.33h15.33c0.27,0,0.52,0.11,0.7,0.29c0.18,0.18,0.29,0.43,0.29,0.7v12.07v12.07
                                c0,0.41-0.24,0.73-0.56,0.89c-0.32,0.15-0.72,0.14-1.04-0.11l-15.33-12.07l-15.33-12.07c-0.37-0.29-0.45-0.73-0.32-1.1
                                C868.85,246.25,869.19,245.95,869.66,245.95z"/>
                        </g>
                        <path class="st2" d="M867.68,299.62c-4.18,1.62-8.13,2.72-11.91,3.41c-3.79,0.69-7.41,0.97-10.94,0.97
                            c-8.27,0-16.01-1.56-24.07-3.13c-8.06-1.56-16.43-3.13-25.96-3.13c-2.76,0-5.62,0.13-8.6,0.43s-6.07,0.77-9.31,1.45
                            c-15.81,3.31-27.31,11.63-35.41,20.37c-8.1,8.75-12.8,17.92-14.99,22.95c-0.14,0.31-0.08,0.61,0.08,0.84s0.43,0.37,0.71,0.37
                            c0.1,0,0.2-0.02,0.29-0.06c0.1-0.04,0.19-0.1,0.28-0.18c3.34-3.08,9.51-8.07,18.27-12.29c8.76-4.22,20.12-7.68,33.81-7.68
                            c3.27,0,6.68,0.2,10.22,0.63c3.54,0.43,7.21,1.1,11,2.04c3.16,0.78,6.19,1.34,9.11,1.7s5.72,0.53,8.41,0.53
                            c14.97,0,26.39-5.26,34.6-11.38c8.22-6.13,13.23-13.12,15.42-16.59c0.19-0.3,0.17-0.63,0.02-0.88c-0.15-0.25-0.42-0.43-0.73-0.43
                            c-0.05,0-0.1,0-0.16,0.01C867.79,299.58,867.74,299.59,867.68,299.62z"/>
                        <g>
                            <path class="st0" d="M775.82,294.49c2.82-0.59,5.6-1.04,8.37-1.37s5.54-0.52,8.33-0.59c0.27-0.01,0.51-0.12,0.68-0.29
                                c0.17-0.18,0.28-0.42,0.28-0.69v-8.03v-8.03c0-0.27,0.11-0.52,0.29-0.7s0.43-0.29,0.7-0.29h30.77h30.77
                                c0.27,0,0.52-0.11,0.7-0.29c0.18-0.18,0.29-0.43,0.29-0.7v-13.28v-13.28c0-0.27-0.11-0.52-0.29-0.7s-0.43-0.29-0.7-0.29h-49.06
                                H757.9c-0.27,0-0.52,0.11-0.7,0.29c-0.18,0.18-0.29,0.43-0.29,0.7v19.62v19.62v6.78v6.78c0,0.37,0.19,0.67,0.47,0.83
                                c0.28,0.17,0.63,0.2,0.96,0.03c2.59-1.31,5.34-2.51,8.25-3.54C769.5,296.04,772.57,295.17,775.82,294.49z"/>
                            <path class="st0" d="M779.9,329.18c-4.22,0-8.21,0.35-11.94,0.97s-7.23,1.49-10.47,2.53c-0.2,0.07-0.38,0.2-0.49,0.36
                                c-0.12,0.17-0.19,0.37-0.19,0.59v14.25v14.25c0,0.27,0.11,0.52,0.29,0.7c0.18,0.18,0.43,0.29,0.7,0.29h14.51h14.51h2.84h2.84
                                c0.27,0,0.52-0.11,0.7-0.29c0.18-0.18,0.29-0.43,0.29-0.7v-15.45v-15.45c0-0.24-0.09-0.47-0.24-0.65
                                c-0.15-0.18-0.36-0.3-0.6-0.34c-2.15-0.35-4.3-0.61-6.42-0.78C784.1,329.27,781.99,329.18,779.9,329.18z"/>
                        </g>
                        <g>
                            <path class="st0" d="M864.85,254.02v20.22v20.22c0,0.16,0.08,0.29,0.2,0.37c0.12,0.08,0.27,0.1,0.41,0.03
                                c0.4-0.18,0.82-0.32,1.25-0.41c0.42-0.09,0.86-0.14,1.29-0.14c1.5,0,2.92,0.55,4.01,1.52s1.86,2.33,2.06,3.97
                                c0.08,0.69,0.02,1.39-0.15,2.06c-0.17,0.67-0.46,1.31-0.83,1.9c-0.72,1.14-1.75,2.66-3.1,4.42s-3.02,3.77-5.02,5.86
                                c-0.04,0.04-0.07,0.09-0.09,0.14c-0.02,0.05-0.03,0.11-0.03,0.16v23.84v23.84c0,0.27,0.11,0.52,0.29,0.7
                                c0.18,0.18,0.43,0.29,0.7,0.29h17.24h17.24c0.27,0,0.52-0.11,0.7-0.29c0.18-0.18,0.29-0.43,0.29-0.7v-40.44v-40.44
                                c0-0.15-0.03-0.3-0.1-0.43c-0.06-0.13-0.16-0.25-0.28-0.34l-17.24-13.57l-17.24-13.57c-0.32-0.25-0.72-0.27-1.04-0.11
                                C865.09,253.28,864.85,253.6,864.85,254.02z"/>
                        </g>
                        <path class="st0" d="M730.31,325.51c2.11-3.02,4.58-6.16,7.44-9.24s6.11-6.1,9.8-8.89c1.39-3.77,2.12-7.56,2.52-10.87
                            s0.45-6.14,0.48-8c0.05-2.82,0.16-8.71-1.27-15.21c-1.44-6.5-4.42-13.6-10.57-18.83c-2.46-2.1-5.13-3.67-7.37-4.77
                            c-2.25-1.1-4.07-1.72-4.87-1.93c-2.68-0.71-5.48-1.16-8.3-1.43c-2.82-0.28-5.66-0.37-8.43-0.37h-35.87h-35.87
                            c-0.27,0-0.52,0.11-0.7,0.29c-0.18,0.18-0.29,0.43-0.29,0.7v57.54v57.54c0,0.27,0.11,0.52,0.29,0.7c0.18,0.18,0.43,0.29,0.7,0.29
                            h17.31h17.31c0.27,0,0.52-0.11,0.7-0.29c0.18-0.18,0.29-0.43,0.29-0.7v-16.16v-16.16c0-0.27,0.11-0.52,0.29-0.7
                            c0.18-0.18,0.43-0.29,0.7-0.29h17.57h17.57c2.77,0,5.61-0.1,8.43-0.37c2.82-0.28,5.62-0.73,8.3-1.43c0.37-0.1,0.9-0.26,1.55-0.49
                            C728.67,326.19,729.45,325.89,730.31,325.51z M713.52,300.13L713.52,300.13L713.52,300.13l-19.47,0h-19.47
                            c-0.27,0-0.52-0.11-0.7-0.29s-0.29-0.43-0.29-0.7v-11.8v-11.8c0-0.27,0.11-0.52,0.29-0.7c0.18-0.18,0.43-0.29,0.7-0.29h19.46
                            h19.46c0,0,0,0,0.01,0c0,0,0,0,0.01,0c3.1,0,5.92,1.43,7.95,3.75c2.03,2.31,3.29,5.51,3.29,9.04c0,3.53-1.26,6.73-3.29,9.04
                            C719.44,298.7,716.63,300.13,713.52,300.13z"/>
                        <path class="st3" d="M442.68,281.28c0-4.41-1.6-7.89-4.65-10.27c-3.05-2.38-7.55-3.66-13.36-3.66c-2.8,0-5.05,0.06-6.83,0.16
                            c-1.78,0.1-3.11,0.24-4.09,0.39c-0.26,0.04-0.48,0.17-0.64,0.36c-0.16,0.19-0.25,0.43-0.25,0.69c0,2.06,0,7.42,0,12.83
                            c0,5.4,0,10.85,0,13.08c0,0.29,0.12,0.55,0.31,0.74s0.46,0.31,0.75,0.31h4.02h4.02c6.58,0,11.76-1.45,15.29-4.01
                            C440.79,289.34,442.68,285.68,442.68,281.28z"/>
                        <path class="st3" d="M418.48,318.83h-2.28h-2.28c-0.29,0-0.56,0.12-0.75,0.31c-0.19,0.19-0.31,0.46-0.31,0.75v21.19v21.19
                            c0,0.29,0.12,0.56,0.31,0.75c0.19,0.19,0.45,0.31,0.75,0.31c2.43,0,8.69,0,15.1,0c6.41,0,12.97,0,15.97,0
                            c0.39,0,0.71-0.2,0.89-0.49s0.22-0.66,0.06-1.02c-1.01-2.16-2.28-5.4-3.7-9.71c-1.42-4.32-2.99-9.71-4.59-16.18
                            c-1.75-7.05-3.83-11.32-6.83-13.83C427.82,319.57,423.9,318.83,418.48,318.83z"/>
                        <path class="st3" d="M345.22,274.65h15.69h15.69c0.29,0,0.56-0.12,0.75-0.31c0.19-0.19,0.31-0.46,0.31-0.75l0-13.24l0-13.24
                            c0-0.29-0.12-0.56-0.31-0.75c-0.19-0.19-0.45-0.31-0.74-0.31c-4.24,0-20.71,0-37.19,0c-16.47,0-32.95,0-37.21,0
                            c-0.29,0-0.56,0.12-0.75,0.31c-0.19,0.19-0.31,0.46-0.31,0.75v57.56v57.56c0,0.29,0.12,0.56,0.31,0.75
                            c0.19,0.19,0.46,0.31,0.75,0.31h37.19h37.19c0.29,0,0.56-0.12,0.75-0.31c0.19-0.19,0.31-0.46,0.31-0.75v-13.26v-13.26
                            c0-0.29-0.12-0.56-0.31-0.75c-0.19-0.19-0.46-0.31-0.75-0.31h-15.69h-15.69c-0.29,0-0.56-0.12-0.75-0.31s-0.31-0.46-0.31-0.75
                            v-7.59v-7.58c0-0.29,0.12-0.56,0.31-0.75c0.19-0.19,0.46-0.31,0.75-0.31h15.69h15.69c0.29,0,0.56-0.12,0.75-0.31
                            s0.31-0.46,0.31-0.75v-11.64v-11.64c0-0.29-0.12-0.56-0.31-0.75c-0.19-0.19-0.46-0.31-0.75-0.31h-15.69h-15.69
                            c-0.29,0-0.56-0.12-0.75-0.31s-0.31-0.46-0.31-0.75v-7.61v-7.61c0-0.29,0.12-0.56,0.31-0.75S344.93,274.65,345.22,274.65z"/>
                        <path class="st3" d="M496.61,246.05l-21.3,0l-21.3,0c-0.59,0-0.96,0.41-1.04,0.88s0.13,0.97,0.69,1.17
                            c1.84,0.65,3.59,1.39,5.23,2.21c1.64,0.82,3.19,1.73,4.63,2.74c4.17,2.91,7.36,6.48,9.51,10.76c2.15,4.28,3.27,9.26,3.27,14.99
                            c0,7.35-3,13.61-7.25,18.48c-4.25,4.88-9.76,8.37-14.77,10.17c-0.08,0.03-0.14,0.08-0.19,0.14s-0.07,0.14-0.07,0.22
                            c0,0.08,0.02,0.15,0.06,0.21s0.1,0.11,0.17,0.14c4.19,1.79,7.48,4.56,10.13,8.16c2.65,3.6,4.66,8.02,6.3,13.09
                            c1.84,5.79,3.77,12.74,5.56,18.92c1.79,6.18,3.44,11.6,4.72,14.35c0.09,0.19,0.22,0.34,0.39,0.44c0.17,0.11,0.36,0.16,0.57,0.16
                            l7.34,0l7.34,0c7.05,0,15.05-0.39,22.77-2.24c7.71-1.85,15.14-5.17,21.04-11.03c7.37-7.32,11.07-13.8,12.94-20.88
                            s1.89-14.76,1.89-24.48c0-9.14-0.69-17.55-2.87-25.14c-2.18-7.59-5.85-14.36-11.8-20.23c-6.73-6.64-14.2-9.95-21.74-11.61
                            C511.29,246.05,503.66,246.05,496.61,246.05z M516.31,325.43c-0.93,2.1-2.03,3.88-3.31,5.35c-1.28,1.47-2.74,2.63-4.37,3.47
                            c-1.63,0.84-3.82,1.47-6.57,1.89c-2.75,0.42-6.05,0.63-9.91,0.63h-4h-4c-0.29,0-0.56-0.12-0.75-0.31
                            c-0.19-0.19-0.31-0.46-0.31-0.75v-31.05v-31.05c0-0.29,0.12-0.56,0.31-0.75c0.19-0.19,0.46-0.31,0.75-0.31h4.08h4.08
                            c4.79,0,8.9,0.58,12.33,1.73s6.18,2.87,8.25,5.17c2.07,2.3,3.62,5.58,4.65,9.84c1.03,4.26,1.55,9.5,1.55,15.73
                            c0,4.7-0.23,8.76-0.7,12.16C517.93,320.59,517.23,323.34,516.31,325.43z"/>
                        <path class="st3" d="M564.17,363.31h17.21h17.21c0.29,0,0.56-0.12,0.75-0.31c0.19-0.19,0.31-0.46,0.31-0.75V321.8v-40.46
                            c0-0.16-0.04-0.32-0.11-0.46c-0.07-0.14-0.17-0.27-0.3-0.37l-17.21-13.54l-17.21-13.54c-0.35-0.27-0.77-0.29-1.11-0.12
                            c-0.34,0.17-0.59,0.51-0.59,0.95v54v54c0,0.29,0.12,0.56,0.31,0.75S563.88,363.31,564.17,363.31z"/>
                        <path class="st3" d="M607.81,359.33v-0.02v-0.02c0.25-0.07,0.47-0.2,0.64-0.39c0.17-0.19,0.27-0.43,0.27-0.73
                            c0-0.22-0.04-0.4-0.11-0.56c-0.07-0.16-0.18-0.29-0.32-0.39c-0.13-0.1-0.32-0.19-0.55-0.25c-0.24-0.06-0.53-0.1-0.88-0.1
                            c-0.28,0-0.51,0.01-0.72,0.03c-0.21,0.02-0.4,0.05-0.6,0.08v2.29v2.29h0.37h0.37v-0.99v-0.99h0.26h0.26
                            c0.24,0,0.46,0.08,0.64,0.23c0.18,0.14,0.3,0.35,0.34,0.58c0.05,0.3,0.11,0.55,0.16,0.75c0.05,0.2,0.11,0.34,0.16,0.41h0.39h0.39
                            c-0.03-0.07-0.08-0.18-0.13-0.36s-0.1-0.42-0.16-0.78c-0.05-0.34-0.14-0.6-0.27-0.79C608.19,359.47,608.01,359.36,607.81,359.33z
                            M606.8,359.03h-0.27h-0.27v-0.78v-0.78c0.06-0.01,0.14-0.02,0.23-0.02s0.19-0.01,0.3-0.01c0.42,0,0.71,0.1,0.88,0.25
                            c0.18,0.15,0.25,0.35,0.25,0.56c0,0.28-0.13,0.48-0.33,0.6C607.4,358.98,607.11,359.03,606.8,359.03z"/>
                        <path class="st3" d="M607.07,355.07L607.07,355.07h-0.02c-1.14,0-2.17,0.46-2.92,1.2c-0.75,0.74-1.21,1.77-1.21,2.92
                            c0,1.16,0.46,2.19,1.21,2.93c0.75,0.74,1.78,1.2,2.92,1.2c1.15,0,2.18-0.45,2.92-1.2c0.74-0.74,1.2-1.77,1.2-2.93
                            c0-1.15-0.45-2.18-1.19-2.92C609.23,355.53,608.21,355.07,607.07,355.07z M607.07,362.67c-0.92,0-1.76-0.38-2.37-1.01
                            s-0.98-1.5-0.98-2.47c0-0.96,0.37-1.83,0.98-2.46c0.6-0.63,1.44-1.02,2.35-1.02h0.01h0.01c0.93,0,1.76,0.39,2.37,1.02
                            c0.6,0.63,0.98,1.5,0.98,2.47c0,0.97-0.37,1.85-0.98,2.47C608.83,362.3,607.99,362.69,607.07,362.67z"/>
                        <path class="st4" d="M599.64,271.09V259.1v-11.99c0-0.29-0.12-0.56-0.31-0.75c-0.19-0.19-0.46-0.31-0.75-0.31h-15.23h-15.23
                            c-0.5,0-0.86,0.32-1,0.71s-0.05,0.87,0.34,1.18l15.23,11.99l15.23,11.99c0.35,0.27,0.77,0.29,1.11,0.12
                            C599.38,271.88,599.64,271.53,599.64,271.09z"/>
                        <path class="st5" d="M262.53,280.36l-17.21-13.54l-17.21-13.54c-0.35-0.27-0.77-0.29-1.11-0.12c-0.34,0.17-0.59,0.51-0.59,0.95
                            v54.01v54.01c0,0.29,0.12,0.56,0.31,0.75c0.19,0.19,0.46,0.31,0.75,0.31h17.21h17.21c0.29,0,0.56-0.12,0.75-0.31
                            s0.31-0.46,0.31-0.75v-40.46v-40.46c0-0.16-0.04-0.32-0.11-0.46C262.76,280.59,262.66,280.46,262.53,280.36z"/>
                        <path class="st6" d="M231.41,245.89h15.23h15.23c0.29,0,0.56,0.12,0.75,0.31s0.31,0.46,0.31,0.75v11.99v11.99
                            c0,0.44-0.25,0.78-0.6,0.95s-0.77,0.15-1.11-0.12l-15.23-11.99l-15.23-11.99c-0.39-0.31-0.48-0.78-0.34-1.18
                            C230.55,246.21,230.91,245.89,231.41,245.89z"/>
                        <path class="st5" d="M203.73,259.15c-6.73-6.64-14.2-9.95-21.75-11.6c-7.55-1.65-15.18-1.65-22.23-1.65l-79.34,0.01l-79.34,0.01
                            c-0.29,0-0.56,0.12-0.75,0.31c-0.19,0.19-0.31,0.46-0.31,0.75v13.24v13.24c0,0.29,0.12,0.56,0.31,0.75s0.45,0.31,0.75,0.31
                            c2.85,0,11.04,0,19.24,0s16.39,0,19.24,0c0.29,0,0.56,0.12,0.75,0.31c0.19,0.19,0.31,0.46,0.31,0.75v43.27v43.27
                            c0,0.29,0.12,0.56,0.31,0.75c0.19,0.19,0.46,0.31,0.75,0.31l16.52,0.01l16.52,0.01c0.29,0,0.56-0.12,0.75-0.31
                            c0.19-0.19,0.31-0.46,0.31-0.75v-37.28v-37.28c-0.01-3.44,1.23-6.51,3.29-9.04c2.07-2.53,4.96-4.53,8.26-5.8
                            c0.08-0.01,0.16-0.02,0.23-0.04c0.08-0.01,0.16-0.03,0.23-0.05c0.03-0.01,0.05-0.01,0.08-0.02s0.05-0.01,0.08-0.02v-1.22v-1.22
                            c0-0.35,0.14-0.66,0.37-0.89c0.23-0.23,0.54-0.37,0.89-0.37h0.29h0.29c0.17,0,0.32-0.07,0.43-0.18c0.11-0.11,0.18-0.26,0.18-0.43
                            l0-0.96v-0.96c0-0.17-0.07-0.32-0.18-0.43c-0.11-0.11-0.27-0.18-0.44-0.18c-0.17,0-0.33-0.08-0.44-0.19
                            c-0.11-0.12-0.18-0.27-0.18-0.45l0-1.91v-1.91c0-0.18,0.08-0.33,0.2-0.44s0.29-0.17,0.47-0.18c0.16-0.01,0.3-0.08,0.41-0.19
                            c0.1-0.11,0.17-0.26,0.17-0.42v-0.36v-0.36c0-0.18,0.07-0.34,0.19-0.45s0.28-0.19,0.45-0.19h1.03h1.03h1.03h1.03
                            c0.18,0,0.34,0.07,0.45,0.19c0.12,0.12,0.19,0.28,0.19,0.45v0.36v0.36c0,0.16,0.06,0.31,0.17,0.42c0.1,0.11,0.25,0.18,0.41,0.19
                            c0.18,0.01,0.35,0.07,0.47,0.18c0.12,0.11,0.2,0.26,0.2,0.44v1.74l0,1.74c0,0.35-0.07,0.59-0.19,0.74
                            c-0.12,0.15-0.28,0.22-0.45,0.24c-0.17,0.02-0.32,0.08-0.43,0.19c-0.11,0.11-0.18,0.25-0.18,0.42l0,0.96l0,0.96
                            c0,0.17,0.07,0.32,0.18,0.43c0.11,0.11,0.26,0.18,0.43,0.18h0.29H97c0.35,0,0.66,0.14,0.89,0.37c0.23,0.23,0.37,0.54,0.37,0.89
                            v1.2v1.2c0.03,0.01,0.06,0.01,0.09,0.02s0.06,0.01,0.09,0.02c3.3,1.2,6.21,3.12,8.34,5.57c2.13,2.45,3.47,5.44,3.63,8.79v0.05
                            v0.05c0,0.09,0.01,0.17,0.01,0.26c0.01,0.09,0.01,0.17,0.01,0.26l-0.01,37.28l-0.01,37.28c0,0.29,0.12,0.56,0.31,0.75
                            c0.19,0.19,0.46,0.31,0.75,0.31l24.15,0l24.15,0c7.05,0,15.05-0.39,22.77-2.24c7.72-1.85,15.14-5.17,21.04-11.03
                            c7.37-7.32,11.07-13.8,12.94-20.88c1.87-7.08,1.89-14.76,1.89-24.49c0-9.14-0.69-17.55-2.87-25.15
                            C213.35,271.79,209.68,265.02,203.73,259.15z M109.16,265.76c0,0.32-0.07,0.64-0.33,0.88c-0.26,0.24-0.71,0.4-1.48,0.38
                            c-1.54-0.03-3.23-0.25-4.69-0.5c-1.46-0.25-2.7-0.53-3.36-0.69c-0.15-0.04-0.28-0.12-0.37-0.24c-0.09-0.12-0.14-0.26-0.14-0.42
                            l0-0.97l0-0.97l0-0.97l0-0.97c0-0.16,0.05-0.3,0.14-0.42c0.09-0.12,0.22-0.2,0.37-0.24c0.66-0.16,1.9-0.44,3.36-0.69
                            c1.46-0.25,3.14-0.47,4.69-0.5c0.77-0.02,1.22,0.14,1.48,0.38c0.26,0.24,0.33,0.57,0.33,0.88l0,1.26l0,1.26l0,1.26L109.16,265.76z
                            M179.46,325.28c-0.93,2.1-2.03,3.88-3.31,5.35c-1.28,1.47-2.74,2.63-4.37,3.47c-1.63,0.84-3.82,1.47-6.57,1.89
                            c-2.75,0.42-6.05,0.63-9.91,0.63h-4h-4c-0.29,0-0.56-0.12-0.75-0.31c-0.19-0.19-0.31-0.46-0.31-0.75v-31.05v-31.05
                            c0-0.29,0.12-0.56,0.31-0.75c0.19-0.19,0.46-0.31,0.75-0.31h4.08h4.08c4.79,0,8.9,0.58,12.33,1.73s6.18,2.87,8.25,5.17
                            c2.07,2.3,3.62,5.58,4.65,9.84s1.55,9.51,1.55,15.73c0,4.71-0.23,8.76-0.7,12.16C181.08,320.44,180.38,323.19,179.46,325.28z"/>
                        <g>
                            <g>
                                <path class="st5" d="M270.34,354.9c1.14,0,2.17,0.46,2.91,1.2c0.74,0.74,1.19,1.77,1.19,2.92c0,1.16-0.45,2.19-1.2,2.93
                                    s-1.77,1.2-2.92,1.2c-1.14,0-2.17-0.45-2.92-1.2s-1.21-1.77-1.21-2.93c0-1.15,0.46-2.18,1.21-2.92c0.75-0.74,1.78-1.2,2.92-1.2
                                    H270.34L270.34,354.9z M270.32,355.54c-0.92,0-1.75,0.39-2.35,1.02c-0.6,0.63-0.98,1.5-0.98,2.46c0,0.98,0.37,1.85,0.98,2.48
                                    c0.61,0.63,1.44,1.01,2.37,1.01c0.93,0.01,1.77-0.37,2.37-1c0.6-0.63,0.98-1.5,0.98-2.47c0-0.97-0.37-1.84-0.98-2.47
                                    c-0.61-0.63-1.44-1.02-2.37-1.02H270.32L270.32,355.54z M269.54,361.4h-0.37h-0.37v-2.29v-2.29c0.2-0.03,0.39-0.06,0.6-0.08
                                    c0.21-0.02,0.44-0.03,0.72-0.03c0.35,0,0.65,0.04,0.88,0.1c0.24,0.06,0.42,0.15,0.55,0.25c0.14,0.1,0.25,0.23,0.32,0.39
                                    c0.07,0.16,0.11,0.34,0.11,0.56c0,0.3-0.1,0.54-0.27,0.73c-0.17,0.19-0.39,0.32-0.64,0.39v0.02v0.02
                                    c0.21,0.04,0.38,0.14,0.51,0.33c0.13,0.18,0.22,0.45,0.27,0.79c0.06,0.35,0.11,0.6,0.16,0.78c0.05,0.18,0.09,0.28,0.13,0.36
                                    h-0.39h-0.39c-0.05-0.07-0.1-0.21-0.16-0.41c-0.05-0.2-0.11-0.45-0.16-0.75c-0.05-0.3-0.15-0.5-0.31-0.62
                                    c-0.16-0.13-0.38-0.18-0.68-0.18h-0.26h-0.26v0.99V361.4z M269.54,358.86h0.27h0.27c0.31,0,0.6-0.05,0.8-0.18
                                    c0.21-0.12,0.33-0.32,0.33-0.6c0-0.2-0.07-0.41-0.25-0.56c-0.18-0.15-0.46-0.25-0.88-0.25c-0.11,0-0.22,0-0.3,0.01
                                    c-0.09,0.01-0.16,0.02-0.23,0.02v0.78V358.86z"/>
                            </g>
                        </g>
                        <path class="st7" d="M99.95,72.02c-2.99-0.45-6.01-0.97-8.97-1.72c-2.96-0.75-5.85-1.73-8.57-3.09c-2.77-1.38-5.5-3.27-7.44-5.65
                            s-3.08-5.27-2.67-8.66c0.47-3.89,3.99-6.6,8.23-8.37c4.24-1.77,9.19-2.59,12.51-2.71c0.93-0.03,14.15-0.05,33.27-0.06
                            s44.13-0.01,68.63-0.01c0.92,0,1.59-0.58,1.84-1.31c0.25-0.73,0.09-1.59-0.64-2.17l-23.98-18.86L148.18,0.55
                            c-0.18-0.14-0.37-0.24-0.58-0.32c-0.21-0.07-0.43-0.11-0.65-0.11c-13.05,0.02-25.34,0.04-34.38,0.06S97.76,0.21,97.76,0.21l0,0
                            l0,0C56.03,0.16,33.65,13.71,21.7,27.27S8.23,54.42,8.23,54.42c-0.83,9.52,0.67,18.56,4.24,26.71s9.22,15.39,16.68,21.31
                            c6.88,5.46,14.62,9.71,22.75,13.11s16.65,5.96,25.09,8.05c3.57,0.88,7.17,1.68,10.78,2.4c3.61,0.73,7.23,1.39,10.86,2
                            c2.18,0.37,4.45,0.9,6.72,1.37c2.27,0.47,4.56,0.87,6.76,1c1.32,0.08,2.41-0.25,3.45-0.76c1.04-0.51,2.02-1.21,3.11-1.88
                            c4.1-2.49,8.7-3.37,13.35-3.24c4.65,0.13,9.35,1.28,13.64,2.85c3.03,1.11,5.98,2.47,8.81,4.02c2.84,1.55,5.57,3.28,8.19,5.14
                            c0.31,0.22,0.62,0.45,0.92,0.68c0.3,0.23,0.61,0.47,0.91,0.7c0.7,0.54,1.68,1.35,2.06,2.02c0.38,0.67,0.15,1.2-1.57,1.18
                            c-4.88-0.05-9.58,0.84-14.05,2.42c-4.47,1.58-8.72,3.86-12.7,6.57c-2.95,2.01-6.08,4.28-9.43,5.89c-3.35,1.61-6.9,2.56-10.7,1.94
                            c-2.29-0.37-4.02-0.83-5.19-1.14s-1.78-0.48-1.8-0.27c-0.19,1.93-0.53,3.87-1.01,5.93c-0.48,2.06-1.11,4.22-1.9,6.57
                            c-0.07,0.21-0.15,0.41-0.26,0.61c-0.1,0.2-0.22,0.38-0.37,0.55c-0.87,1.02-1.92,1.79-3.03,2.45s-2.31,1.23-3.45,1.86
                            c-1.13,0.62-2.23,1.28-3.3,1.99c-1.08,0.7-2.12,1.45-3.14,2.23c-2.04,1.56-3.96,3.28-5.74,5.13c-1.78,1.85-3.43,3.84-4.91,5.93
                            c-0.69,0.98-1.35,1.99-1.97,3.02c-0.62,1.03-1.2,2.09-1.74,3.16c-0.38,0.75-0.74,1.52-1.08,2.29c-0.34,0.77-0.66,1.55-0.95,2.34
                            c-0.06,0.16-0.16,0.3-0.28,0.42c-0.12,0.11-0.27,0.19-0.44,0.23c-0.31,0.06-0.55-0.05-0.73-0.25c-0.18-0.2-0.31-0.47-0.41-0.72
                            c-0.24-0.59-0.47-1.17-0.68-1.76c-0.21-0.59-0.41-1.18-0.59-1.79c-0.27-0.94-0.51-1.89-0.73-2.84s-0.43-1.91-0.65-2.86
                            c-1.47-6.27-2.32-13.43-1.49-20.22c0.83-6.79,3.32-13.22,8.55-18.03c0.17-0.15,0.34-0.29,0.52-0.43s0.36-0.28,0.53-0.42
                            c0.71-0.57,0.85-1.43,0.59-2.15c-0.26-0.72-0.92-1.29-1.83-1.3c0,0-16.27-0.08-34.24-0.17s-37.64-0.19-44.43-0.22
                            c-0.63,0-1.18,0.29-1.53,0.74c-0.35,0.45-0.51,1.05-0.35,1.66c1.47,5.75,3.49,11.35,6.07,16.67s5.73,10.37,9.47,14.99
                            c3.37,4.17,7.19,7.95,11.33,11.32c4.15,3.37,8.63,6.34,13.34,8.9c4.19,2.27,8.54,4.21,13,5.86c4.46,1.65,9.04,3,13.67,4.1
                            c8.89,2.1,18.02,3.24,27.18,3.59s18.37-0.1,27.42-1.18c8.67-1.04,17.37-2.68,25.74-5.27c8.37-2.6,16.4-6.16,23.74-11.06
                            c0.04-0.02,0.07-0.05,0.11-0.07c0.04-0.02,0.07-0.05,0.11-0.07c3.56-2.4,6.9-5.1,9.98-8.08c3.07-2.98,5.87-6.24,8.33-9.76
                            c1.95-2.8,3.69-5.74,5.19-8.8c1.5-3.06,2.78-6.23,3.81-9.48c0.32-1,1.39-4.07,2.39-8.22c1-4.15,1.92-9.38,1.93-14.68
                            c-0.32-5.33-0.99-8.83-1.58-11c-0.59-2.17-1.1-3-1.1-3c-6.63-21.73-31.69-35.03-55.09-42.9S99.95,72.02,99.95,72.02z"/>
                        <path class="st7" d="M498,63.48l-31.7-24.94l-31.7-24.94c-0.64-0.5-1.43-0.53-2.05-0.22c-0.63,0.3-1.1,0.94-1.1,1.75v99.46v99.46
                            c0,0.54,0.22,1.02,0.57,1.38c0.35,0.35,0.84,0.57,1.38,0.57h31.7h31.7c0.54,0,1.02-0.22,1.38-0.57c0.35-0.35,0.57-0.84,0.57-1.38
                            v-74.52V65.01c0-0.3-0.07-0.59-0.2-0.85C498.42,63.9,498.23,63.66,498,63.48z"/>
                        <g>
                            <path class="st7" d="M512.42,200.82c2.1,0,3.99,0.84,5.35,2.21c1.36,1.37,2.2,3.27,2.2,5.38c0,2.13-0.84,4.03-2.2,5.39
                                s-3.26,2.2-5.38,2.2c-2.1,0-4-0.83-5.38-2.2s-2.24-3.27-2.24-5.39c0-2.11,0.86-4.01,2.24-5.38s3.29-2.21,5.38-2.21h0.01H512.42z
                                M512.39,202c-1.69,0-3.22,0.72-4.33,1.87c-1.11,1.16-1.8,2.76-1.8,4.53c0,1.8,0.69,3.4,1.81,4.56c1.12,1.16,2.66,1.86,4.36,1.86
                                c1.71,0.02,3.25-0.69,4.37-1.84c1.11-1.16,1.8-2.77,1.8-4.56c0-1.78-0.69-3.39-1.8-4.55s-2.66-1.88-4.37-1.88h-0.01H512.39z
                                M510.96,212.8h-0.68h-0.68v-4.22v-4.22c0.36-0.05,0.71-0.1,1.1-0.14c0.39-0.04,0.81-0.06,1.33-0.06c0.65,0,1.19,0.07,1.63,0.18
                                s0.78,0.27,1.02,0.46c0.26,0.19,0.46,0.42,0.6,0.71c0.13,0.29,0.2,0.63,0.2,1.04c0,0.55-0.19,1-0.5,1.35s-0.73,0.58-1.18,0.71
                                v0.03v0.03c0.38,0.07,0.69,0.27,0.94,0.61c0.24,0.34,0.41,0.82,0.5,1.45c0.11,0.65,0.21,1.11,0.3,1.43
                                c0.09,0.32,0.17,0.52,0.24,0.65h-0.71h-0.71c-0.09-0.13-0.19-0.39-0.29-0.75c-0.1-0.36-0.2-0.83-0.3-1.38
                                c-0.08-0.43-0.31-0.81-0.63-1.07s-0.74-0.42-1.18-0.42l-0.49,0h-0.49v1.81V212.8z M510.96,208.11h0.49h0.49
                                c0.57,0,1.1-0.1,1.48-0.33c0.38-0.23,0.61-0.59,0.61-1.1c0-0.38-0.13-0.75-0.46-1.03s-0.85-0.46-1.63-0.46
                                c-0.21,0-0.4,0.01-0.56,0.02s-0.3,0.03-0.42,0.05v1.43V208.11z"/>
                        </g>
                        <path class="st6" d="M440.69,0h28.05h28.05c0.54,0,1.02,0.22,1.38,0.57s0.57,0.84,0.57,1.38v22.09v22.09
                            c0,0.81-0.47,1.45-1.1,1.75c-0.63,0.3-1.41,0.28-2.05-0.22l-28.05-22.09L439.48,3.47c-0.73-0.57-0.89-1.44-0.63-2.17
                            C439.1,0.58,439.76,0,440.69,0z"/>
                        <path class="st5" d="M283.97,214.05l-0.02-79.71l-0.02-79.71c0-0.54-0.22-1.02-0.57-1.38s-0.84-0.57-1.38-0.57h-31.04H219.9
                            c-0.54,0-1.02,0.22-1.38,0.57c-0.35,0.35-0.57,0.84-0.57,1.38v79.71v79.71c0,0.54,0.22,1.02,0.57,1.38
                            c0.35,0.35,0.84,0.57,1.38,0.57l31.07,0l31.07,0c0.54,0,1.02-0.22,1.38-0.57C283.76,215.08,283.98,214.59,283.97,214.05z"/>
                        <path class="st7" d="M300.92,41.74c8.82,0,16.4,1.06,22.71,3.18c6.32,2.12,11.38,5.29,15.19,9.53c1.96,2.18,3.66,5.31,5.12,9.15
                            s2.66,8.39,3.61,13.4c0.09,0.46,0.33,0.86,0.67,1.14c0.34,0.28,0.77,0.45,1.24,0.45l31.68,0l31.68,0c0.6,0,1.12-0.27,1.48-0.68
                            c0.36-0.41,0.54-0.97,0.45-1.56c-1.56-10.02-4.13-19.38-8.13-28.05c-4.01-8.67-9.46-16.64-16.79-23.87
                            c-12.4-12.22-26.15-18.33-40.05-21.38S321.84,0,308.85,0l-69.12,0.01L170.6,0.02c-0.92,0-1.59,0.58-1.84,1.31
                            s-0.09,1.59,0.63,2.17l24.01,18.91l24.01,18.91c0.17,0.14,0.36,0.24,0.57,0.31c0.2,0.07,0.42,0.11,0.64,0.11h41.15H300.92z"/>
                        <path class="st5" d="M351.45,89.53c-0.57,0-1.08,0.24-1.43,0.63c-0.35,0.39-0.55,0.91-0.5,1.48c0.24,2.76,0.42,5.57,0.55,8.41
                            c0.12,2.84,0.18,5.72,0.18,8.59c0,8.67-0.43,16.13-1.28,22.4c-0.85,6.26-2.13,11.33-3.83,15.19c-1.71,3.86-3.74,7.15-6.1,9.86
                            c-2.36,2.71-5.04,4.84-8.05,6.39c-3.01,1.55-7.04,2.71-12.11,3.48c-5.06,0.77-11.15,1.16-18.26,1.16h-1.88h-1.88
                            c-0.54,0-1.02,0.22-1.38,0.57c-0.35,0.35-0.57,0.84-0.57,1.38v22.49v22.49c0,0.54,0.22,1.02,0.57,1.38
                            c0.35,0.35,0.84,0.57,1.38,0.57l6,0l6,0c12.98,0,27.73-0.71,41.94-4.12c14.21-3.41,27.89-9.52,38.75-20.32
                            c13.57-13.49,20.4-25.42,23.83-38.46s3.48-27.19,3.48-45.1c0-2.85-0.04-5.67-0.12-8.45c-0.08-2.78-0.21-5.51-0.38-8.21
                            c-0.03-0.51-0.26-0.97-0.62-1.29s-0.82-0.52-1.34-0.52l-31.49,0L351.45,89.53z"/>
                        <g>
                            <path class="st5" d="M612.84,131.57c0,8.38-1.56,15.41-4.66,21.06c-3.11,5.66-7.56,9.92-13.36,12.78
                                c-5.8,2.87-12.82,4.3-21.06,4.3H552.9V94.89h23.06c7.55,0,14.08,1.4,19.6,4.19c5.52,2.8,9.78,6.9,12.79,12.31
                                C611.34,116.81,612.84,123.54,612.84,131.57z M602.89,131.89c0-6.64-1.1-12.09-3.3-16.35c-2.2-4.26-5.42-7.42-9.64-9.48
                                c-4.23-2.06-9.35-3.09-15.35-3.09h-12.26v58.69h10.17c10.13,0,17.73-2.5,22.79-7.49C600.35,149.16,602.89,141.74,602.89,131.89z"
                                />
                            <path class="st5" d="M650.47,169.72h-27.04v-5.45l8.8-1.99v-59.84l-8.8-2.1v-5.45h27.04v5.45l-8.8,2.1v59.84l8.8,1.99V169.72z"/>
                            <path class="st5" d="M717.64,94.89l-26.72,74.82h-9.43l-26.72-74.82h9.85l16.87,48c0.77,2.03,1.43,3.98,1.99,5.87
                                c0.56,1.89,1.06,3.69,1.52,5.4c0.45,1.71,0.85,3.41,1.21,5.08c0.35-1.68,0.75-3.39,1.2-5.14c0.45-1.75,0.98-3.56,1.57-5.45
                                c0.59-1.89,1.24-3.88,1.94-5.97l16.77-47.79H717.64z"/>
                            <path class="st5" d="M748.87,169.72h-27.04v-5.45l8.8-1.99v-59.84l-8.8-2.1v-5.45h27.04v5.45l-8.8,2.1v59.84l8.8,1.99V169.72z"/>
                            <path class="st5" d="M822.64,169.72h-11l-40.03-62.14h-0.42c0.14,1.61,0.26,3.51,0.37,5.71c0.11,2.2,0.21,4.54,0.31,7.02
                                c0.11,2.48,0.16,5.01,0.16,7.6v41.81h-8.7V94.89h10.9l39.93,61.93h0.42c-0.07-1.12-0.16-2.79-0.26-5.03
                                c-0.1-2.23-0.21-4.68-0.31-7.34c-0.1-2.65-0.16-5.1-0.16-7.34V94.89h8.8V169.72z"/>
                            <path class="st5" d="M875.46,130.21h25.88v36.68c-4.05,1.33-8.14,2.31-12.26,2.93c-4.12,0.63-8.8,0.94-14.04,0.94
                                c-7.76,0-14.29-1.55-19.6-4.66c-5.31-3.11-9.35-7.55-12.1-13.31c-2.76-5.76-4.14-12.59-4.14-20.49c0-7.82,1.54-14.6,4.61-20.33
                                c3.07-5.73,7.49-10.18,13.26-13.36c5.76-3.18,12.73-4.77,20.91-4.77c4.19,0,8.16,0.38,11.89,1.15c3.74,0.77,7.21,1.85,10.43,3.25
                                l-3.56,8.17c-2.66-1.19-5.64-2.2-8.96-3.04c-3.32-0.84-6.76-1.26-10.32-1.26c-5.94,0-11.04,1.22-15.3,3.67
                                c-4.26,2.45-7.51,5.92-9.75,10.43c-2.24,4.51-3.35,9.87-3.35,16.09c0,5.94,0.96,11.2,2.88,15.77c1.92,4.58,4.94,8.14,9.06,10.69
                                c4.12,2.55,9.54,3.82,16.24,3.82c3.28,0,6.08-0.17,8.38-0.52c2.31-0.35,4.4-0.77,6.29-1.26v-22.22h-16.45V130.21z"/>
                        </g>
                        <g>
                            <path class="st5" d="M602.8,58.9c0,4.72-1.14,8.71-3.43,11.99c-2.29,3.28-5.55,5.8-9.79,7.57c-4.24,1.77-9.24,2.65-14.99,2.65
                                c-2.95,0-5.77-0.15-8.46-0.44c-2.69-0.3-5.14-0.72-7.36-1.27c-2.21-0.55-4.17-1.23-5.86-2.04v-9.5c2.66,1.18,5.96,2.25,9.9,3.2
                                c3.95,0.96,8.02,1.44,12.23,1.44c3.91,0,7.19-0.51,9.85-1.55c2.66-1.03,4.65-2.5,5.98-4.42c1.33-1.92,1.99-4.16,1.99-6.74
                                c0-2.58-0.55-4.75-1.66-6.52s-3.01-3.41-5.7-4.92c-2.69-1.51-6.44-3.11-11.23-4.81c-3.39-1.25-6.36-2.6-8.91-4.03
                                c-2.55-1.44-4.67-3.08-6.36-4.92c-1.7-1.84-2.97-3.94-3.82-6.3c-0.85-2.36-1.27-5.08-1.27-8.18c0-4.2,1.07-7.79,3.21-10.77
                                c2.14-2.98,5.11-5.29,8.91-6.91c3.8-1.62,8.17-2.43,13.11-2.43c4.35,0,8.33,0.41,11.95,1.22c3.61,0.81,6.9,1.88,9.85,3.2
                                l-3.1,8.51c-2.73-1.18-5.7-2.17-8.91-2.98c-3.21-0.81-6.55-1.22-10.01-1.22c-3.32,0-6.09,0.48-8.3,1.44
                                c-2.21,0.96-3.87,2.3-4.98,4.03c-1.11,1.73-1.66,3.74-1.66,6.02c0,2.65,0.55,4.86,1.66,6.63s2.91,3.35,5.42,4.75
                                c2.51,1.4,5.9,2.91,10.18,4.53c4.65,1.7,8.58,3.52,11.78,5.47c3.21,1.95,5.64,4.29,7.3,7.02C601.97,51.35,602.8,54.78,602.8,58.9
                                z"/>
                            <path class="st5" d="M652.59,8.73c-4.2,0-8,0.72-11.4,2.15c-3.39,1.44-6.27,3.54-8.63,6.3c-2.36,2.76-4.17,6.12-5.42,10.06
                                c-1.26,3.94-1.88,8.38-1.88,13.32c0,6.48,1.01,12.12,3.04,16.91c2.03,4.79,5.05,8.47,9.07,11.05c4.02,2.58,9.05,3.87,15.1,3.87
                                c3.47,0,6.75-0.29,9.85-0.88c3.1-0.59,6.12-1.33,9.07-2.21v8.62c-2.95,1.1-5.99,1.92-9.13,2.43c-3.14,0.51-6.88,0.77-11.23,0.77
                                c-8.04,0-14.75-1.66-20.14-4.97c-5.39-3.31-9.42-8.03-12.12-14.15c-2.69-6.11-4.04-13.3-4.04-21.55c0-5.97,0.83-11.42,2.49-16.36
                                c1.66-4.94,4.09-9.21,7.3-12.82c3.21-3.61,7.17-6.39,11.89-8.34C641.16,0.98,646.58,0,652.7,0c4.06,0,7.97,0.41,11.73,1.22
                                c3.76,0.81,7.12,1.95,10.07,3.43l-3.98,8.4c-2.43-1.1-5.15-2.1-8.13-2.98S656.13,8.73,652.59,8.73z"/>
                            <path class="st5" d="M748.75,52.16c0,5.45-1.11,10.37-3.32,14.75c-2.21,4.38-5.59,7.85-10.12,10.39
                                c-4.54,2.54-10.31,3.81-17.32,3.81c-9.81,0-17.28-2.67-22.41-8.01c-5.13-5.34-7.69-12.39-7.69-21.16V1.1h9.96v51.17
                                c0,6.41,1.72,11.38,5.15,14.92c3.43,3.54,8.61,5.3,15.55,5.3c4.79,0,8.69-0.87,11.67-2.6c2.99-1.73,5.18-4.13,6.58-7.18
                                c1.4-3.06,2.1-6.58,2.1-10.55V1.1h9.85V52.16z"/>
                            <path class="st5" d="M792.12,1.1c9.88,0,17.35,1.46,22.41,4.37c5.05,2.91,7.58,7.98,7.58,15.2c0,3.09-0.57,5.84-1.72,8.23
                                c-1.14,2.4-2.84,4.38-5.09,5.97c-2.25,1.59-5,2.67-8.24,3.26v0.55c3.32,0.52,6.32,1.47,9.02,2.87c2.69,1.4,4.81,3.39,6.36,5.97
                                c1.55,2.58,2.32,5.93,2.32,10.06c0,4.79-1.11,8.86-3.32,12.21c-2.21,3.35-5.37,5.9-9.46,7.63c-4.09,1.73-8.98,2.6-14.66,2.6
                                h-27.77V1.1H792.12z M794.11,34.7c6.79,0,11.43-1.09,13.94-3.26c2.51-2.17,3.76-5.4,3.76-9.67c0-4.35-1.53-7.46-4.59-9.34
                                c-3.06-1.88-7.95-2.82-14.66-2.82h-13.06V34.7H794.11z M779.51,42.99v28.62h15.93c7.01,0,11.88-1.36,14.61-4.09
                                c2.73-2.73,4.09-6.3,4.09-10.72c0-2.8-0.61-5.23-1.83-7.29c-1.22-2.06-3.25-3.66-6.09-4.81c-2.84-1.14-6.69-1.71-11.56-1.71
                                H779.51z"/>
                            <path class="st5" d="M891.04,80.01l-9.52-24.42h-31.31l-9.41,24.42h-10.07l30.87-79.24h8.96l30.76,79.24H891.04z M869.69,22.88
                                c-0.22-0.59-0.59-1.66-1.11-3.2c-0.52-1.55-1.01-3.15-1.49-4.81c-0.48-1.66-0.87-2.93-1.16-3.81c-0.37,1.47-0.76,2.97-1.16,4.48
                                c-0.41,1.51-0.81,2.89-1.22,4.14c-0.41,1.25-0.76,2.32-1.05,3.2l-8.96,23.87h25.01L869.69,22.88z"/>
                        </g>
                        <g>
                            <path class="st5" d="M565.2,215.56h-12.3v-2.75l3.78-1.11v-23.91l-3.78-1.15v-2.75h12.3v2.75l-3.73,1.15v23.91l3.73,1.11V215.56z
                                "/>
                            <path class="st5" d="M597.18,215.56h-5.82l-16.03-25.6h-0.18c0.06,0.77,0.11,1.63,0.16,2.57c0.04,0.95,0.09,1.94,0.13,2.97
                                c0.04,1.04,0.07,2.1,0.07,3.19v16.86h-4.35v-31.68h5.77l15.99,25.47h0.13c-0.03-0.62-0.06-1.4-0.09-2.35
                                c-0.03-0.95-0.07-1.96-0.11-3.04c-0.04-1.08-0.08-2.09-0.11-3.04v-17.04h4.44V215.56z"/>
                            <path class="st5" d="M616.28,215.56h-4.8V188h-9.51v-4.13h23.81V188h-9.51V215.56z"/>
                            <path class="st5" d="M648.48,215.56h-17.86v-31.68h17.86v4.08h-13.06v9.05h12.26v4.04h-12.26v10.38h13.06V215.56z"/>
                            <path class="st5" d="M664.25,183.88c2.72,0,4.96,0.34,6.71,1.02c1.75,0.68,3.05,1.69,3.91,3.04c0.86,1.35,1.29,3.04,1.29,5.08
                                c0,1.63-0.3,2.99-0.91,4.1c-0.61,1.11-1.38,2.01-2.33,2.71c-0.95,0.7-1.94,1.25-2.98,1.66l8.88,14.06h-5.51l-7.64-12.82h-5.51
                                v12.82h-4.8v-31.68H664.25z M663.94,187.96h-3.78v10.78h4c2.46,0,4.25-0.47,5.37-1.4c1.13-0.93,1.69-2.31,1.69-4.15
                                c0-1.27-0.26-2.28-0.78-3.04c-0.52-0.75-1.32-1.31-2.4-1.66C666.97,188.14,665.6,187.96,663.94,187.96z"/>
                            <path class="st5" d="M709.51,215.56h-5.82l-16.03-25.6h-0.18c0.06,0.77,0.11,1.63,0.16,2.57c0.04,0.95,0.09,1.94,0.13,2.97
                                c0.04,1.04,0.07,2.1,0.07,3.19v16.86h-4.35v-31.68h5.77l15.99,25.47h0.13c-0.03-0.62-0.06-1.4-0.09-2.35
                                c-0.03-0.95-0.07-1.96-0.11-3.04c-0.04-1.08-0.08-2.09-0.11-3.04v-17.04h4.44V215.56z"/>
                            <path class="st5" d="M737.72,215.56l-3.38-9.14h-12.26l-3.38,9.14h-4.97l12.04-31.81h4.97l11.99,31.81H737.72z M729.81,193.33
                                c-0.09-0.33-0.24-0.82-0.47-1.49c-0.22-0.67-0.44-1.35-0.64-2.04c-0.21-0.69-0.37-1.26-0.49-1.71c-0.15,0.59-0.32,1.24-0.51,1.93
                                c-0.19,0.7-0.38,1.33-0.55,1.91c-0.18,0.58-0.33,1.04-0.44,1.4l-3.2,8.92h9.5L729.81,193.33z"/>
                            <path class="st5" d="M754.55,215.56h-4.8V188h-9.51v-4.13h23.81V188h-9.51V215.56z"/>
                            <path class="st5" d="M778.67,215.56h-12.3v-2.75l3.78-1.11v-23.91l-3.78-1.15v-2.75h12.3v2.75l-3.73,1.15v23.91l3.73,1.11V215.56
                                z"/>
                            <path class="st5" d="M812.6,199.67c0,2.46-0.3,4.68-0.91,6.68c-0.61,2-1.53,3.72-2.75,5.17c-1.23,1.45-2.77,2.56-4.62,3.33
                                c-1.85,0.77-4.01,1.15-6.46,1.15c-2.52,0-4.7-0.39-6.55-1.18c-1.85-0.78-3.39-1.89-4.62-3.33c-1.23-1.43-2.14-3.16-2.73-5.17
                                c-0.59-2.01-0.89-4.24-0.89-6.7c0-3.25,0.53-6.09,1.6-8.52s2.7-4.32,4.91-5.68c2.21-1.36,4.98-2.04,8.33-2.04
                                c3.26,0,5.97,0.67,8.15,2.02c2.18,1.35,3.81,3.24,4.91,5.68C812.05,193.53,812.6,196.39,812.6,199.67z M788.13,199.67
                                c0,2.54,0.34,4.73,1.02,6.54c0.68,1.82,1.75,3.22,3.2,4.21c1.45,0.99,3.29,1.49,5.51,1.49c2.25,0,4.09-0.49,5.51-1.49
                                c1.42-0.99,2.47-2.4,3.15-4.21c0.68-1.82,1.02-4,1.02-6.54c0-3.82-0.76-6.79-2.29-8.94c-1.53-2.14-3.98-3.22-7.35-3.22
                                c-2.25,0-4.1,0.49-5.55,1.46c-1.45,0.98-2.52,2.37-3.2,4.17C788.47,194.96,788.13,197.13,788.13,199.67z"/>
                            <path class="st5" d="M845.52,215.56h-5.82l-16.03-25.6h-0.18c0.06,0.77,0.11,1.63,0.16,2.57c0.04,0.95,0.09,1.94,0.13,2.97
                                c0.04,1.04,0.07,2.1,0.07,3.19v16.86h-4.35v-31.68h5.77l15.99,25.47h0.13c-0.03-0.62-0.06-1.4-0.09-2.35
                                c-0.03-0.95-0.07-1.96-0.11-3.04c-0.04-1.08-0.08-2.09-0.11-3.04v-17.04h4.44V215.56z"/>
                            <path class="st5" d="M873.72,215.56l-3.38-9.14h-12.26l-3.38,9.14h-4.97l12.04-31.81h4.97l11.99,31.81H873.72z M865.81,193.33
                                c-0.09-0.33-0.24-0.82-0.47-1.49c-0.22-0.67-0.44-1.35-0.64-2.04c-0.21-0.69-0.37-1.26-0.49-1.71c-0.15,0.59-0.32,1.24-0.51,1.93
                                c-0.19,0.7-0.38,1.33-0.55,1.91c-0.18,0.58-0.33,1.04-0.44,1.4l-3.2,8.92h9.5L865.81,193.33z"/>
                            <path class="st5" d="M883,215.56v-31.68h4.8v27.55h13.55v4.13H883z"/>
                        </g>
                    </g>
                </g>
                </svg>
            </figure>
        </div>   -->
      </article>
    </section>

    <hr />
    <section class="linha">
      <div class="logo">
        <figure>
          <img
            src="@/assets/logo-scubalab-mergulho.webp"
            alt="Logo Scubalab Mergulho"
          />
        </figure>
        <div>©Scubalab Mergulho. Todos os direitos reservados.</div>
      </div>
      <div class="socialMedia">
        <figure>
          <a href="https://www.facebook.com/scubalabmergulho" target="blank">
            <img
              src="@/assets/icon/svg/facebook.svg"
              class="img"
              alt="Facebook Scubalab"
            />
          </a>
        </figure>
        <figure>
          <a href="https://www.instagram.com/scubalab/" target="blank">
            <img
              src="@/assets/icon/svg/instagram.svg"
              class="img"
              alt="Instagram Scubalab"
            />
          </a>
        </figure>
        <figure>
          <a href="https://www.youtube.com/scubalabmergulho" target="blank">
            <img
              src="@/assets/icon/svg/youtube.svg"
              class="img"
              alt="Youtube Scubalab"
            />
          </a>
        </figure>
        <!-- <figure>
          <img
            src="@/assets/icon/svg/telegram.svg"
            class="img"
            alt="Telegram Scubalab"
          />
        </figure> -->
      </div>
    </section>
  </footer>
</template>

<script>
export default {
  name: "Footer",
  methods: {
    scrollToTop() {
      // console.log("ativo");
      window.scroll({
        top: 0,
        behavior: "smooth",
      });
    },
  },
};
</script>

<style scoped>
/* body {
  font-family: "Open Sans", Roboto, Verdana, "Helvetica Neue", sans-serif;
} */
/* .wppIcon {
  width: 50px;
  height: 50px;
} */
.wppFixed {
  position: fixed;
  bottom: 20px;
  right: 30px;
  z-index: 99;
}
.topIcon {
  width: 24px;
  height: 24px;
  stroke: var(--lightBlue);
}
/* RAINBOW */
.wppIcon {
  animation: wppIcon 2s ease-in-out infinite;
  width: 50px;
  height: 50px;
  transform: rotate(10deg);
}
.wppIcon:after {
  animation: wppIcon_shadow 1s ease-in-out infinite;
  transform: rotate(10deg);
  transform-origin: 50% 50%;
}
@keyframes wppIcon {
  50% {
    transform: rotate(-10deg);
  }
}
@keyframes wppIcon_shadow {
  50% {
    transform: rotate(-50deg) translate(10px) scale(0.1);
    opacity: 0.05;
  }
}

.wppIcon {
  fill: white;
  width: 50px;
  height: 50px;
  padding: 3px;
  background-color: #4dc247;
  border-radius: 50%;
  box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.4);
  /* box-shadow: 2px 2px 11px rgba(0,0,0,0.7); */
  position: fixed;
  bottom: 20px;
  right: 20px;
  z-index: 10;
}

.wppIcon:hover {
  box-shadow: 2px 2px 11px rgba(0, 0, 0, 0.7);
}

/* FIM WPP */
.top:hover > .topIcon {
  stroke: var(--darkGray);
}
.top {
  position: absolute;
  right: 20px;
  display: grid;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 30px;
  /* padding: 10px 30px; */
  background-color: var(--darkGray);
  font-size: 0.778em;
  line-height: 20px;
  text-align: center;
  border-radius: 5px;
  display: inline-block;
  cursor: pointer;
  border: 2px solid var(--lightBlue);
  outline: none;
}
.top:hover {
  background-color: var(--lightBlue);
}
footer {
  display: grid;
  grid-template-columns: 1fr;
  bottom: 0px;
  width: 100%;
  align-content: center;
  background-color: var(--darkGray);
  padding: 20px 0;
  justify-content: center;
}

section {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  /* padding: 0 60px; */
  padding: 0 20px;
  max-width: 1200px;
  width: 100%;
  /* margin: 0 auto; */
}
.containerInfo {
  justify-self: center;
}
.infoItens img {
  width: 21px;
}
.infosItem {
  padding: 60px 0;
}
.infoItens {
  display: grid;
  grid-template-columns: 30px 1fr;
  align-items: center;
  font-style: normal;
  font-size: 0.8em;
  color: var(--white);
  margin: 10px;
  width: 100%;
}
.contactItem {
  display: grid;
  grid-template-columns: 1fr;
  font-style: normal;
  font-size: 0.8em;
  color: var(--white);
  margin: 10px 0 0 20px;
  padding: 60px 0;
}

.contactItem div svg{
    padding-top: 15px;
    width: 150px;
    fill: #ffffff
}

.contactItens {
  display: grid;
  grid-template-columns: repeat(3, minmax(100px, max-content));
  justify-content: flex-start;
  grid-gap: 10px;
}

hr {
  width: 100%;
}

.linha {
  display: grid;
  /* grid-template-columns: 1fr 200px; */
  padding: 0 20px;
  align-items: center;
  justify-content: space-between;
  font-family: "Open Sans", Roboto, Verdana, "Helvetica Neue", sans-serif;
  font-style: normal;
  font-size: 0.8em;
  color: #fafafa;
  grid-gap: 10px;
  max-width: 1200px;
  margin: 10px auto;
}

.logo {
  display: grid;
  grid-template-columns: 200px 310px;
  width: 100%;
  justify-items: flex-start;
  align-items: center;
}
.socialMedia {
  display: grid;
  grid-template-columns: repeat(3, 32px);
  justify-content: flex-end;
  grid-gap: 10px;
}

.voltar-topo {
  text-decoration: none;
  padding-bottom: 0;
  position: absolute;
}
@media screen and (max-width: 768px) {
  .linha,
  .logo,
  section {
    grid-template-columns: 1fr;
    padding: 10px 20px;
  }
  .infosItem,
  .contactItem,
  .contactItem p {
    padding: 10px 0;
  }
  .logo,
  .socialMedia {
    justify-items: center;
    justify-content: center;
    grid-gap: 10px;
    padding: 10px 0;
  }
}
</style>