<template>
  <section class="container">
    <!-- <article class="courseItens">
      <div>
        <router-link
          :to="{
            name: 'aprenda-mergulhar',
          }"
          @click.native="uncheck"
        >
          <figure>
            <img
              src="@/assets/icon/svg/home/diveIcon.svg"
              class="img"
              alt="Youtube Scubalab"
            />
          </figure>
          <h3>Aprenda a Mergulhar</h3>
          <p class="text24">Conheça o mundo submerso!</p>
        </router-link>
      </div>
      <div>
        <figure>
          <img
            src="@/assets/icon/svg/home/certifyIcon.svg"
            class="img"
            alt="Youtube Scubalab"
          />
        </figure>
        <h3>Já Mergulha?</h3>
        <p class="text24">Conheça outros cursos!</p>
      </div>
      <div>
        <figure>
          <img
            src="@/assets/icon/svg/home/boatIcon.svg"
            class="img"
            alt="Youtube Scubalab"
          />
        </figure>
        <h3>Viaje e Mergulhe</h3>
        <p class="text24">Divirta-se e conheça novos destinos!</p>
      </div>
      <div>
        <figure>
          <img
            src="@/assets/icon/svg/home/equipamentIcon.svg"
            class="img"
            alt="Youtube Scubalab"
          />
        </figure>
        <h3>Manutenção de Equipamentos</h3>
        <p class="text24">Mergulhe com segurança!</p>
      </div>
      <div>
        <figure>
          <img
            src="@/assets/icon/svg/home/sotreIcon.svg"
            class="img"
            alt="Youtube Scubalab"
          />
        </figure>
        <h3>Loja Virtual</h3>
        <p class="text24">Equipe-se e aproveite o seu mergulho!</p>
      </div>
      <div>
        <figure>
          <img
            src="@/assets/icon/svg/home/divingTipsIcon.svg"
            class="img"
            alt="Youtube Scubalab"
          />
        </figure>
        <h3>Dicas de Equipamentos</h3>
        <p class="text24">Conheça melhor os equipamentos!</p>
      </div>
    </article> -->
    <nav class="navBreadcrumb">
      <ol
        class="breadcrumb"
        itemscope
        itemtype="https://schema.org/BreadcrumbList"
      >
        <li
          class="breadcrumb-item"
          itemprop="itemListElement"
          itemscope
          itemtype="https://schema.org/ListItem"
        >
          <router-link :to="{ name: 'home' }" itemprop="item"
            ><span itemprop="name">Home</span>
          </router-link>
          <meta itemprop="position" content="1" />
        </li>
        <li
          class="breadcrumb-item-active"
          itemprop="itemListElement"
          itemscope
          itemtype="https://schema.org/ListItem"
        >
          <span itemprop="name"> Cursos</span>
          <meta itemprop="position" content="2" />
        </li>
      </ol>
    </nav>
    <article class="container">
      <h1 class="navBreadcrumb">Cursos de Mergulho</h1>
      <ul class="courseItens">
        <li v-for="course in courses" :key="course.id">
          <div>
            <!-- <pre>
             {{ course }}
             </pre
            > -->
            <router-link
              :to="{
                name: 'curso',
                params: { typeCourse: course.typeCourse },
              }"
            >
              <!-- <router-link
              :to="{
                name: 'curso',
                params: { typeCourse: course.typeCourse, course: course },
              }"
            > -->
              <figure v-if="course.banner">
                <img :src="getImgUrl(course.banner)" :alt="course.course" />
              </figure>
              <h3>{{ course.course }}</h3>
            </router-link>
          </div>
        </li>
      </ul>
    </article>
  </section>
</template>

<script>
import courses from "@/api/course.json";
import { mapState } from "vuex";
export default {
  name: "Scubalab",
  metaInfo() {
    return {
      // title: "Cursos de mergulho",
      meta: [
        {
          name: "description",
          content:
            "Scubalab Mergulho - Centro de Mergulho - Descubra a Maravilhas do Mundo Submerso",
        },
        { name: "robots", content: "index, follow" },
        { property: "og:locale", content: "pt-br" },
        {
          property: "og:type",
          content: "website",
        },
        {
          property: "og:title",
          content: "Cursos - Scubalab Mergulho",
        },
        {
          property: "og:description",
          content:
            "Scubalab Mergulho - Centro de Mergulho - Descubra a Maravilhas do Mundo Submerso",
        },
        {
          property: "og:url",
          content: "https://www.scubalab.com.br/cursos",
        },
        {
          property: "og:site_name",
          content: "Cursos - Scubalab Mergulho",
        },
        {
          property: "og:image",
          content: process.env.VUE_APP_BASE_URL + "/open-graph-scubalab.png",
        },
        {
          property: "og:image:alt",
          content: "Cursos - Scubalab Mergulho",
        },
        {
          property: "article:tag",
          content:
            "Scubalab Mergulho - Centro de Mergulho - Descubra a Maravilhas do Mundo Submerso",
        },
        {
          property: "twitter:image",
          content: process.env.VUE_APP_BASE_URL + "/open-graph-scubalab.png",
        },
        {
          property: "twitter:title",
          content: "Cursos - Scubalab Mergulho",
        },
        {
          name: "twitter:card",
          content: "summary",
        },
        {
          property: "twitter:description",
          content:
            "Scubalab Mergulho - Centro de Mergulho - Descubra a Maravilhas do Mundo Submerso",
        },
      ],
    };
  },
  // data() {
  //   return {
  //     courses: courses,
  //   };
  // },
  methods: {
    getImgUrl(img) {
      // console.log("img", img);
      return require("@/assets/course/thumbs/" + img);
    },
  },
  computed: {
    ...mapState(["courses"]),
  },
  created() {
    this.$store.commit("update_courses", courses);
    // this.fetchCourses();
    // this.$store.dispatch("getCourses");
  },
};
</script>

<style scoped>
ul.circle {
  /* list-style-type: disc; */
  color: var(--white);
  padding-bottom: 30px;
  text-align: justify;
}
/*  */
.circle li::before {
  content: "●";
  color: var(--lightBlue);
  /* line-height: var(--lnH3); */
  display: inline-block;
  width: 1em;
  margin-right: 0.5em;
  text-align: right;
  direction: rtl;
  padding-bottom: 10px;
}
.containerTravels {
  background: var(--blue);
  margin: 60px auto 0 auto;
  padding: 60px 0;
}
.travels {
  display: grid;
  grid-template-columns: minmax(auto, 430px) minmax(200px, 500px);
  align-items: center;
  justify-items: center;
  padding: 0 20px;
  max-width: 1200px;
  margin: 0 auto;
  grid-gap: 30px;
}
.travels h2 {
  color: var(--lightBlue);
  padding-bottom: 30px;
}
.containerDepoiments {
  display: grid;
  align-items: center;
  justify-items: center;
  padding: 20px 0 60px 0;
  max-width: 800px;
  margin: 0 auto;
}
.containerDepoiments ul {
  display: grid;
  grid-gap: 30px;
  margin-top: 30px;
}
.depoimentItens {
  display: grid;
  grid-template-columns: 211px 1fr;
  justify-items: flex-start;
  align-items: center;
  width: 100%;
  /* max-width: 1200px; */
  margin: 0 auto;
  grid-gap: 40px;
}
.courseItens {
  display: grid;
  grid-template-columns: repeat(3, 400px);
  align-items: flex-start;
  justify-content: center;
  padding: 20px;
  max-width: 1200px;
  margin: 0 auto;
  grid-gap: 20px 10px;
}
.courseItens div {
  justify-content: center;
  text-align: center;
}
/* ############################ 
#       CONTAINER VIDEO 
#############################*/
.containerVideo {
  background: var(--blue);
  margin: 60px auto;
}
.contentVideo {
  display: grid;
  grid-template-columns: minmax(auto, 360px) minmax(300px, 840px);
  align-items: center;
  justify-items: center;
  padding: 60px 20px;
  max-width: 1200px;
  margin: 0 auto;
  grid-gap: 10px;
}

.contentVideo p {
  max-width: 250px;
  text-align: center;
  color: #fff;
  font-size: 1em;
  line-height: 30px;
  font-weight: bold;
}
#video {
  /* max-width: 600px; */
  width: 100%;
  border-radius: 5px;
}

/* ############################ 
#       ÍCONES 
#############################*/

@media screen and (max-width: 768px) {
  .travels figure {
    flex: 1 1 300px;
    display: flex;
  }
  .courseItens {
    grid-template-columns: repeat(2, 300px);
  }
  .travels,
  .contentVideo {
    grid-template-columns: 1fr;
  }
  .containerImg {
    /* background-image: url("../assets/fundoHome.webp"); */
    height: 100%;
    background-repeat: no-repeat;
    background-position: initial;
    grid-template-columns: 1fr;
  }
  .calendarItem {
    grid-column: 2;
    padding-right: 100px;
  }
}
@media screen and (max-width: 550px) {
  .containerDepoiments {
    text-align: center;
    margin-bottom: 60px;
  }
  .containerDepoiments,
  .depoimentItens {
    grid-template-columns: 1fr;
    padding: 0 20px;
    justify-items: center;
  }
  .containerImg,
  .courseItens {
    grid-template-columns: 1fr;
  }
  .calendarItem {
    grid-template-columns: 1fr;
    grid-column: 1;
    padding: 300px 0 0 0;
  }
  .containerCalendar {
    height: 500px;
    justify-content: center;
    justify-items: center;
  }
}
</style>