import Vue from "vue";
import VueRouter from "vue-router";

//##################################################
//       HOME
//################################################## 
import Home from "../views/Home.vue";
import About from "../views/About.vue";
import Calendar from "../views/Calendar.vue";
import Courses from "../views/courses/Courses.vue";
import Course from "../views/courses/Course.vue";
import Travels from "../views/travels/Travels.vue";
import Services from "../views/services/Services.vue";
import Contact from "../views/Contact.vue";
import Maintenance from "../views/maintenance/Maintenance.vue";
import Tips from "../views/tips/Tips.vue";

Vue.use(VueRouter);

const routes = [
  // {
  //   path: "*",
  //   redirect: "pagina-nao-encontrada",
  // },
  {
    path: "*",
    redirect: "/home",
  },
  {
    path: "/",
    name: "home",
    component: Home,
    meta: {
      login: true,
      title: "Scubalab Mergulho"
    },
  },
  {
    path: "/sobre",
    name: "sobre",
    component: About,
    meta: {
      login: true,
      title: "Sobre - Scubalab Mergulho"
    },
  },
  {
    path: "/calendario",
    name: "calendario",
    component: Calendar,
    meta: {
      login: true,
      title: "Calendário - Scubalab Mergulho"
    },
  },
  {
    path: "/cursos",
    name: "cursos",
    component: Courses,
    meta: {
      login: true,
      title: "Cursos de Mergulho - Scubalab Mergulho"
    },
  },
  {
    path: "/cursos/curso/:typeCourse",
    // path: "/cursos/curso/:idCourse/:typeCourse",
    name: "curso",
    component: Course,
    props: true,
    meta: {
      login: true,
      title: "Curso de Mergulho - Scubalab Mergulho"
    },
  },
  {
    path: "/viagens",
    name: "viagens",
    component: Travels,
    meta: {
      login: true,
      title: "Viagens - Scubalab Mergulho"
    },
  },
  {
    path: "/servicos",
    name: "servicos",
    component: Services,
    meta: {
      login: true,
      title: "Serviços - Scubalab Mergulho"
    },
  },
  {
    path: "/manutencao-e-reparos-de-equipamentos-de-mergulho",
    name: "manutencao",
    component: Maintenance,
    meta: {
      login: true,
      title: "Manutenção e Reparo de Equipamentos de Mergulho - Scubalab Mergulho"
    },
  },
  {
    path: "/dicas-de-equipamento",
    name: "dicas",
    component: Tips,
    meta: {
      login: true,
      title: "Dicas de Equipamentos de Mergulho - Scubalab Mergulho"
    },
  },
  {
    path: "/contato",
    name: "contato",
    component: Contact,
    meta: {
      login: true,
      title: "Contato - Scubalab Mergulho"
    },
  },

  //##################################################
  //       STATUS - redirecionamento
  //################################################## 

  //###### 403
  // {
  //   path: "restrito",
  //   name: "restrito",
  //   component: Forbidden,
  //   meta: {
  //     login: true,
  //     title: "Restrito - SimpliEsgoto®"
  //   },
  // },
  // //###### 404
  // {
  //   path: "pagina-nao-encontrada",
  //   name: "pagina-nao-encontrada",
  //   component: NotFound,
  //   meta: {
  //     login: true,
  //     title: "Página não encontrada - SimpliEsgoto®"
  //   },
  // },
  // //###### 500
  // {
  //   path: "erro-interno",
  //   name: "erro-interno",
  //   component: ErrorBackend,
  //   meta: {
  //     login: true,
  //     title: "Erro Interno - SimpliEsgoto®"
  //   },
  // },

]

const router = new VueRouter({
  mode: "history", /*tira o "#" da rota*/
  base: process.env.BASE_URL, /* é a "/" */
  routes,

});

router.beforeEach((to, from, next) => {
  /*###################################################################
    ##           TITLE DAS PÁGINAS DINÂMICO 
    ###################################################################*/
  const DEFAULT_TITLE = 'Scubalab';
  document.title = to.meta.title || DEFAULT_TITLE
  // console.log("router index", to, from)

  /*###################################################################
  ##         VOLTA AO TOPO SOMENTE QUANDO MUDA DE PÁGINA
  ###################################################################*/
  to.name === from.name && to.params === from.params ? '' : next(window.scrollTo(0, 0))
  // console.log(to)
  /*###################################################################
  ##    VERIFICANDO TOKEN + SE TEM URL NA MEMÓRIA
  ###################################################################*/
  next();
});

export default router;
