<template>
  <section class="container">
    <nav class="navBreadcrumb">
      <ol class="breadcrumb">
        <li class="breadcrumb-item">
          <router-link :to="{ name: 'home' }">Home</router-link>
        </li>
        <li class="breadcrumb-item-active">
          <span> Calendário</span>
        </li>
      </ol>
    </nav>

    <article class="containerPrimary">
      <div class="travels">
        <div>
          <h1>Calendário</h1>
          <h2>Confira os próximos cursos e viagens!</h2>
          <p class="calendar">
            <iframe
              src="https://calendar.google.com/calendar/embed?height=600&wkst=1&bgcolor=%23ffffff&ctz=America%2FSao_Paulo&src=c2N1YmFsYWIuc2JjQGdtYWlsLmNvbQ&color=%23D50000&showTitle=0&showNav=1&showDate=0&showPrint=0&showTabs=1&showCalendars=0&showTz=0"
              style="border-width: 0"
              width="800"
              height="600"
              frameborder="0"
              scrolling="no"
            ></iframe>
          </p>
        </div>
      </div>
    </article>
  </section>
</template>

<script>
export default {
  name: "Scubalab",
};
</script>

<style scoped>
.containerPrimary {
  display: grid;
  grid-template-columns: 1fr;
  padding: 0 20px;
  max-width: 1200px;
  margin: 0 auto;
  grid-gap: 30px;
  /* padding: 30px 0; */
}
.calendar {
  display: grid;
  grid-template-columns: minmax(300px, 800px);
  width: 100%;
  justify-content: center;
  margin: 60px 0;
}
.calendar .iframe {
  /* grid-template-columns: minmax(300px, 800px); */
  width: 100%;
  height: 600px;
}

iframe html table {
  display: none;
}

iframe html body#container.calendar-container div#calendarContainer1 {
  background-color: #ffff !important;
}
@media screen and (max-width: 768px) {
  /* .containerPrimary {
    margin: 0 20px;
  } */
  .calendar .iframe {
    /* grid-template-columns: minmax(300px, 800px); */
    width: 100%;
    height: 400px;
  }
}
@media screen and (max-width: 550px) {
  .calendar .iframe {
    /* grid-template-columns: minmax(300px, 800px); */
    width: 100%;
    height: 300px;
  }
}
</style>