<template>
  <div id="app">
    <Header />
    <main id="main">
      <transition mode="out-in">
        <router-view />
      </transition>
    </main>
    <Footer />
  </div>
</template>

<script>
import Header from "@/components/Header.vue";
import Footer from "@/components/Footer.vue";

export default {
  name: "App",
  components: {
    Header,
    Footer,
  },
};
</script>

<style>
/* open-sans-regular - cyrillic */
@font-face {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  src: url("~@/fonts/open-sans-v23-latin-regular.eot"); /* IE9 Compat Modes */
  src: local(""),
    url("~@/fonts/open-sans-v23-latin-regular.eot?#iefix")
      format("embedded-opentype"),
    /* IE6-IE8 */ url("~@/fonts/open-sans-v23-latin-regular.woff2")
      format("woff2"),
    /* Super Modern Browsers */ url("~@/fonts/open-sans-v23-latin-regular.woff")
      format("woff"),
    /* Modern Browsers */ url("~@/fonts/open-sans-v23-latin-regular.ttf")
      format("truetype"),
    /* Safari, Android, iOS */
      url("~@/fonts/open-sans-v23-latin-regular.svg#OpenSans") format("svg"); /* Legacy iOS */
}
/* open-sans-700 - latin */
@font-face {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 700;
  src: url("~@/fonts/open-sans-v23-latin-700.eot"); /* IE9 Compat Modes */
  src: local(""),
    url("~@/fonts/open-sans-v23-latin-700.eot?#iefix")
      format("embedded-opentype"),
    /* IE6-IE8 */ url("~@/fonts/open-sans-v23-latin-700.woff2") format("woff2"),
    /* Super Modern Browsers */ url("~@/fonts/open-sans-v23-latin-700.woff")
      format("woff"),
    /* Modern Browsers */ url("~@/fonts/open-sans-v23-latin-700.ttf")
      format("truetype"),
    /* Safari, Android, iOS */
      url("~@/fonts/open-sans-v23-latin-700.svg#OpenSans") format("svg"); /* Legacy iOS */
}

:root {
  --darkBlue: #031c36;
  --blue: #1264a7;
  --darkGray: #202932;
  --lightBlue: #71e9ff;
  --lightgray: #6c6c6d;
  --white: #ffffff;
  --h1: max(30px, 2.6vw);
  --h2: max(1em, 1.5em);
  --h3: 1em;
  --text24: 0.8em;
  --text22: 0.733em;
  --text16: 0.533em;
  --text14: 0.467em;
  --lnH1: max(30px, 3vw);
  --lnH2: 40px;
  --lnH3: 30px;
  --lntxt24: 30px;
  --lntxt22: 28px;
  --lntxt18: 25px;
  --lntxt16: 23px;
  --lntxt14: 20px;
}

/* var(--darkBlue)
 var(--blue)
 var(--darkGray)
 var(--lightBlue)
 var(--lightgray)
 var(--white) */

/*
48pc == 1.600em - 60px
36px == 1200 - 40px
 24px == .8em - 30px

 18pc == 0.600em - 25px
  16px == 0.533em - 23px
  14px == 0.467em - 20px

*/
.color-blueDark{
    color: var(--darkGray);
}

.pb-1{
    padding-bottom: 10px;
}
/*#####################
#      TRANSITION 
######################*/
.v-enter,
.v-leave-to {
  transform: translate3d(-20px, 0, 0);
  opacity: 0;
}

.v-enter-active,
.v-leave-active {
  transition: all 0.3s;
}

.btn {
  /* width: 140px;
  height: 50px; */
  padding: 10px 30px;
  background-color: var(--darkBlue);
  font-size: 0.778em;
  line-height: 20px;
  text-align: center;
  /* padding: 12px; */
  color: var(--lightBlue);
  border: none;
  border-radius: 5px;
  display: inline-block;
  cursor: pointer;
  border: 2px solid var(--darkBlue);
  outline: none;
}

.btn:hover {
  margin-top: 0;
  border: 2px solid var(--lightBlue);
  border-radius: 5px;
  text-align: center;
  cursor: pointer;
}
/*##################################
#      FORMATAÇÃO TEXTOS
##################################*/
h1 {
  color: #fff;
  font-size: var(--h1);
  line-height: var(--lnH1);
  margin-bottom: 10px;
  color: var(--blue);
  font-weight: bold;
  /* text-transform: uppercase; */
}
h2 {
  color: #fff;
  font-size: var(--h2);
  line-height: var(--lnH2);
  margin-bottom: 10px;
  color: var(--blue);
  font-weight: bold;
  /* text-transform: uppercase; */
}
h3 {
  color: #fff;
  font-size: var(--h3);
  line-height: var(--lnH3);
  margin-bottom: 10px;
  color: var(--darkBlue);
  font-weight: bold;
  /* text-transform: uppercase; */
}

h4 {
  color: #fff;
  font-size: var(--h4);
  line-height: var(--lnH4);
  margin-bottom: 10px;
  color: var(--blue);
  font-weight: bold;
  /* text-transform: uppercase; */
}

.text24 {
  color: #fff;
  font-size: var(--text24);
  line-height: var(--lntext24);
  margin-bottom: 10px;
  color: var(--darkBlue);
  font-weight: normal;
  /* text-transform: uppercase; */
}

/*##################################
#      SCROLL  PERSONALIZADO 
##################################*/
*::-webkit-scrollbar {
  width: 10px; /* width of the entire scrollbar */
}
*::-webkit-scrollbar-track {
  background: var(--white); /* color of the tracking area */
}
*::-webkit-scrollbar-thumb {
  background-color: var(--lightgray); /* color of the scroll thumb */
  /* border-radius: 20px; roundness of the scroll thumb */
  border: 1px solid var(--white); /* creates padding around scroll thumb */
}
* {
  scrollbar-width: thin;
  scrollbar-color: #6c6c6d var(--white);
}

/*##################################
#      RESET CSS 
##################################*/
* {
  box-sizing: border-box;
}

html {
  scroll-behavior: smooth;
}

a,
button {
  text-decoration: none;
}

ol,
ul {
  list-style: none;
}

html,
body,
div,
span,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
a,
em,
img,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
article,
footer,
header,
nav,
section,
main,
figure,
button {
  margin: 0;
  padding: 0;
  border: 0;
  vertical-align: baseline;
}

p,
a,
ul,
button {
  font-size: 1em;
  font-weight: normal;
}
body {
  /* display: grid;
  align-items: center; */
  font-family: "Open Sans", Roboto, Verdana, "Helvetica Neue", sans-serif;
}

#app {
  display: flex;
  min-height: 100vh;
  flex-direction: column;
}

#main {
  /* padding: 0 60px; */

  flex: 1;
  overflow-y: auto;
  /* margin-top: 105px; */
}
.content{
    display: grid;
    justify-content: start;    
    padding: 0 20px;
    max-width: 1200px;
    width: 100%;
}

.mt-3{
    margin: 20px auto 30px auto;
}
/*######################
#      BREADCRUMB
######################*/
.navBreadcrumb {
  display: grid;
  grid-template-columns: 1fr 50px;
  justify-content: space-between;
  margin: 20px auto 30px auto;
  padding: 0 20px;
  max-width: 1200px;
  width: 100%;
}
.breadcrumb {
  display: flex;
  flex-wrap: wrap;
  margin-top: 0px;
  font-size: 0.8rem;
  /* white-space: nowrap */
}

.breadcrumb-item-active,
.breadcrumb-itens {
  display: flex;
  grid-gap: 8px;
  justify-content: flex-start;
}
.breadcrumb-item-active span {
  color: var(--lightgray);
  margin-left: -8px;
}

li + li.breadcrumb-item::before {
  content: "/";
  color: var(--blue);
  margin: 0 5px;
}

li + li.breadcrumb-item-active::before {
  content: "/";
  color: #6c6c6d;
  margin: 0 5px;
  /* margin-right: 5px; */
}

.breadcrumb-item a {
  color: var(--blue);
}

.breadcrumb-item a:hover {
  color: #6c6c6d;
  text-decoration: underline;
  text-underline-offset: 4px;
  text-underline-position: under;
}

/*#  TRANSITION MODAL 
######################*/
.fade-enter,
.fade-leave-to {
  transform: translate3d(-20px, 0, 0);
  opacity: 0;
}
.fade-leave,
.fade-enter-to {
  opacity: 1;
}
.fade-enter-active {
  transition: opacity 100ms ease-in-out;
  transition: all 0.3s;
}

@media screen and (max-width: 768px) {
  .navBreadcrumb {
    padding: 0 20px;
    margin: 0;
  }
}
</style>
