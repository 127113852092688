<template>
  <section class="container">
    <nav class="navBreadcrumb">
      <ol class="breadcrumb">
        <li class="breadcrumb-item">
          <router-link :to="{ name: 'home' }">Home</router-link>
        </li>
        <li class="breadcrumb-item-active">
          <span> Sobre</span>
        </li>
      </ol>
    </nav>
    <article class="container">
      <h1 class="content">Sobre</h1>
      <div class="content">
        <p>Bem-vindo à Scubalab Mergulho, onde nossa paixão pelo mundo subaquático é compartilhada de maneira descontraída e segura. Nós somos mais do que um centro de mergulho – somos uma comunidade de entusiastas que encontraram um lar aqui. Imagine-se explorando as profundezas do oceano, confiante de que está em mãos experientes e dedicadas.</p>
        <p>Inicialmente começamos como uma empresa de manutenção de equipamentos de mergulho, liderada pelo habilidoso técnico Cezar Torres, conhecido por sua excelência e ética de trabalho impecável. Nossa história evoluiu naturalmente à medida que abrimos nossas portas para uma experiência além da manutenção, tornando-nos um centro completo de mergulho, além de um coração aberto para ajudar aqueles que enfrentam traumas aquáticos. Com mais de 20 anos de experiência, continuamos a mergulhar de cabeça em novas aventuras, sempre com a segurança e a diversão em primeiro plano.</p>
        <p>Na Scubalab, nossa equipe é nossa força. Mergulhadores apaixonados e certificados, preparados para mergulhar em qualquer situação. Nossos cursos de especialização, que vão desde a manutenção de equipamentos até o treinamento em nitrox e o2 provider, garantem que nossa equipe possa enfrentar qualquer desafio. Estamos aqui para apoiar você em cada mergulho, oferecendo um suporte seguro e uma dose extra de diversão. Junte-se a nós e vamos explorar as maravilhas do oceano juntos!</p>  
      </div>
      <div class="content">
        <b class="color-blueDark pb-1">Compromisso com o Meio Ambiente:</b>
      <p>Somos verdadeiros defensores da preservação dos ambientes subaquáticos, pois acreditamos firmemente que a exploração responsável e a conservação são parceiras inseparáveis.Comprometemo-nos integralmente a proteger os lugares que tanto amamos. Além de promover a conscientização sobre a importância dos ecossistemas marinhos, também nos envolvemos ativamente em projetos dedicados à limpeza e conservação dos oceanos.</p>
      </div>
      <h2 class="mt-3">Conheça a nossa equipe</h2>
      <img class="imgGroup" src="../assets/staffs/staff.webp" alt="Foto da Staff da Scubalab" />    
      <article class="containerstaffs">
           <ul>
                <li v-for="staff in staffs" :key="staff.id">
                <div class="staffItens">
                    <figure>
                    <img class="imgStaff" :src="getImgUrl(staff.img)" :alt="staff.name"
                    />
                    </figure>
                    <div>
                    <h3>{{ staff.name }} | {{ staff.certification }}</h3>
                    <p class="text24 mt-1"  v-if="staff.nickname">
                        <b>Apelido:</b> {{ staff.nickname }}
                    </p>
                    <!-- <p class="text24"  v-if="staff.since">
                        {{ staff.since }}
                    </p> -->
                    <p class="text24"  v-if="staff.specialty">
                        <b>Especialidades:</b> {{ staff.specialty }}
                    </p>
                    <p class="text24">
                        {{ staff.description }}
                    </p>
                    </div>
                </div>
                </li>
            </ul>
        </article>
    </article>
  </section>
</template>

<script>
import staffsData from "@/api/staffs.json";

export default {
  name: "Scubalab",
  data() {
    return {
      staffs: staffsData,
    };
  },
  metaInfo() {
    return {
      meta: [
        {
          name: "description",
          content:
            "Scubalab Mergulho - Centro de Mergulho - Descubra a Maravilhas do Mundo Submerso",
        },
        { name: "robots", content: "index, follow" },
        { property: "og:locale", content: "pt-br" },
        {
          property: "og:type",
          content: "website",
        },
        {
          property: "og:title",
          content: "Scubalab Mergulho",
        },
        {
          property: "og:description",
          content:
            "Scubalab Mergulho - Centro de Mergulho - Descubra a Maravilhas do Mundo Submerso",
        },
        {
          property: "og:url",
          content: "https://www.scubalab.com.br/",
        },
        {
          property: "og:site_name",
          content: "Scubalab Mergulho",
        },
        {
          property: "og:image",
          content: process.env.VUE_APP_BASE_URL + "/open-graph-scubalab.png",
        },
        {
          property: "og:image:alt",
          content: "Scubalab Mergulho",
        },
        {
          property: "article:tag",
          content:
            "Scubalab Mergulho - Centro de Mergulho - Descubra a Maravilhas do Mundo Submerso",
        },
        {
          property: "twitter:image",
          content: process.env.VUE_APP_BASE_URL + "/open-graph-scubalab.png",
        },
        {
          property: "twitter:title",
          content: "Scubalab Mergulho",
        },
        {
          name: "twitter:card",
          content: "summary",
        },
        {
          property: "twitter:description",
          content:
            "Scubalab Mergulho - Centro de Mergulho - Descubra a Maravilhas do Mundo Submerso",
        },
      ],
    };
  },
  methods: {
    getImgUrl(img) {
      return require("@/assets/staffs/" + img);
    },
  },
  computed: {
    // ...mapState(["staffs"]),
  },
};
</script>

<style scoped>
.mt-1{
    margin-top: 8px;
}
.container {
  display: grid;
  grid-template-columns: 1fr;
  align-items: center;
  justify-items: center;
  /* padding: 0 20px; */
  max-width: 1200px;
  margin: 0 auto;
  grid-gap: 30px;
}

p ~ p{
    padding: 10px 0;
}

/* ############################ 
#       EQUIPE 
#############################*/
.imgStaff{
    max-width: 200px;
    width: 100%;
    /* box-shadow: 0 4px 8px rgba(30,60,90,.2) */
}
.containerstaffs {
  display: grid;
  align-items: center;
  justify-items: center;
  padding: 20px 0 60px 0;
  max-width: 800px;
  margin: 0 auto;
}
.containerstaffs ul {
  display: grid;
  grid-gap: 30px;
  margin-top: 30px;
}
.staffItens {
    /* display: grid;
    grid-template-columns: 1fr;
    justify-items: center;
    width: 100%;
    max-width: 300px;
    line-height: 20px;
    grid-gap: 40px; */
  display: grid;
  grid-template-columns: 211px 1fr;
  justify-items: flex-start;
  align-items: center;
  width: 100%;
  margin: 0 auto;
  grid-gap: 40px;
}

.staffItens p{
    padding: 0;
}

img{
    width: 100%;
}

.imgGroup{
    max-width: 710px;
}

@media screen and (max-width: 550px) {
   .containerstaffs {
    text-align: center;
    margin-bottom: 60px;
  }
  .containerstaffs,
  .staffItens {
    grid-template-columns: 1fr;
    padding: 0 20px;
    justify-items: center;
  }
  .containerImg {
    grid-template-columns: 1fr;
  }

  .container {
   grid-gap: 10px;
}
 
}
@media screen and (max-width: 768px) {
}
@media screen and (max-width: 550px) {
}
</style>